import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import topnetLogo from '../../assets/topnetLogo.svg'
import sonedeLogo from '../../assets/sonedeLogo.svg'
import stegLogo from '../../assets/stegLogo.svg'
import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'
import { Spinner } from 'utils/loading'
import ModalTestStatusUser from 'components/modalTestStatusUser'
import UserContext from 'contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import CarteFact from './carteFact'


interface Props {
  isTopnet: boolean
  isSoned: boolean
  isSteg: boolean
  onChangeVal: (value3: string, value2: string) => void
  onBackChangeStep: any
  onChangeStep: any
  setMontantDinar: (value: string) => void
  setMontantEuro: (value: string) => void
  facture: any
  billerCode: any
  setBillerCode: (value: string) => void
  idFacture: any
  setIdFacture: (value: string) => void
  failedPaiementFacture: boolean
  legnth: any
  functionTracks: any
  handleConfirmPayment: any
  setInvoiceId: (value: string) => void
  paiementFacture: any
  loading: boolean
}

const FacturePaid: React.FC<Props> = ({
  isTopnet,
  isSoned,
  isSteg,
  onChangeVal,
  onBackChangeStep,
  onChangeStep,
  setMontantDinar,
  setMontantEuro,
  facture,
  billerCode,
  setBillerCode,
  idFacture,
  setIdFacture,
  failedPaiementFacture,
  legnth,
  functionTracks,
  handleConfirmPayment,
  setInvoiceId,
  paiementFacture,
  loading,
}) => {
  const { user } = useContext(UserContext)

  const statusComplete = user?.user?.status === 'confirmed' && user?.user?.isDocumentSent
  const statusPending = user?.user?.status === 'pending' && user?.user?.isDocumentSent
  const statusNotFound = user?.user?.status === 'pending' && !user?.user?.isDocumentSent
  const navigate = useNavigate()
  const [ON, setON] = useState(false)
  const [val, setVal] = useState<any>()
  const [indexRecharge, setIndexRecharge] = useState(-1)
  const [openIsModalStatus, setOpenIsModalStatus] = useState(false)
  const openModalStatus = () => {
    setOpenIsModalStatus(true)
  }
  const closeModalStatus = () => {
    setOpenIsModalStatus(false)
  }
 
  return (
    <div>
      <div className="place-items-center sm:overflow-y-auto no-scrollbar sm:max-h-[340px] mx-5 3xl:space-x-[35%] grid grid-cols-2 sm:grid-cols-1 sm:gap-2 sm:mt-8">
        {/* {failedPaiementFacture === true && (
          <div className="relative mt-[8rem] mb-[2rem]">
            <div className="absolute left-0 bottom-0 w-[520px] sm:w-auto border-2 border-dashed border-[#5754E5] text-[20px] text-center text-[#6B6B6B] rounded-2xl p-3">
              Vous n’avez pas de facture à payer
            </div>
          </div>
        )}   */}
        {loading ? (
          <div className="mt-5 ml-[14rem] sm:ml-[5rem]">
            <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
          </div>
        ) : facture && facture?.invoicesList?.length > 0 ? (
          <>

            {facture?.invoicesList?.map((item: any, index: number) => (
              <div key={index}
                onClick={() => {
                  setMontantDinar(item?.AmountTND)
                  setMontantEuro(item?.amountToPay)
                  setBillerCode(item?.billerCode)
                  setIdFacture(item?.id)
                  setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                      setInvoiceId(item?.id)
                      setON(true)
                }}
              >
                {item.billerCode === 's600' && (
                  
                    <CarteFact
                      logo={topnetLogo}
                      montanPayer={item?.amountToPay}
                      montantEnvoyer={item?.AmountTND}
                      bgCatre='bg-[#4382C5]'
                      On={ON}
                      index={index}
                      indexRecharge={indexRecharge}
                    />
                   
                
                 
                )}

                {item.billerCode === 's502' && (
                  // <div className={classNames(isSoned ? '' : 'hidden')}>
                  <div 
                    onClick={() => {
                      setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                      setON(true)
                      setMontantDinar(item?.AmountTND)
                      setMontantEuro(item?.amountToPay)
                      setBillerCode(item?.billerCode)
                      setIdFacture(item?.id)
                    }}>
                       <CarteFact
                        logo={sonedeLogo}
                        montanPayer={item?.amountToPay}
                        montantEnvoyer={item?.AmountTND}
                        bgCatre='bg-[#4382C5]'
                        On={ON}
                        index={index}
                        indexRecharge={indexRecharge}
                      />
                    
                    </div>
                 
                )}

                {item.billerCode === 's701' && (
                  // <div className={classNames(isSteg ? '' : 'hidden')}>
                    // <div className="flex flex-col items-center">
                      <div
                        // className="flex flex-col px-5 py-3 rounded-2xl w-fill-available hover:cursor-pointer"
                        onClick={() => {
                          setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                          setON(true)
                          setMontantDinar(item?.AmountTND)
                          setMontantEuro(item?.amountToPay)
                          setBillerCode(item?.billerCode)
                          setIdFacture(item?.id)
                        }}
                      >
                          <CarteFact
                          logo={stegLogo}
                          montanPayer={item?.amountToPay}
                          montantEnvoyer={item?.AmountTND}
                          bgCatre='bg-[#F5F5F5]'
                          colorText='text-[#2F2F2F]'
                          widhtLogo='w-[24px] h-[40px]'
                          On={ON}
                          index={index}
                          indexRecharge={indexRecharge}
                        />
                      
                      </div>
                    // </div>
              
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="relative mt-[8rem] mb-[2rem]">
            <div className="absolute left-0 bottom-0 w-[520px] sm:w-auto border-2 border-dashed border-[#5754E5] text-[20px] text-center text-[#6B6B6B] rounded-2xl p-3">
              Vous n’avez pas de facture à payer
            </div>
          </div>
        )}
      </div>
      <div className={`flex gap-[16px] justify-center sm:justify-normal my-5 place-self-center sm:w-full`}>
        <button
          className={classNames(
            'text-[18px] text-[#556987] p-2 h-[56px] w-[180px] border border-[#556987] font-sans rounded-xl',
          )}
          onClick={onBackChangeStep}
        >
          Précédent
        </button>
        <button
          className={classNames(
            'text-[18px] text-[#FFF] w-[357px] p-2 h-[56px] rounded-xl  sm:w-full bg-[#4743C9]',
            indexRecharge === -1 || idFacture === '' ? 'bg-gray-400' : 'bg-[#4743C9] ',
          )}
          onClick={() => {
            if (statusComplete) {
              paiementFacture()
              onChangeStep()
              functionTracks('2')
            } else if (statusPending) {
              
              openModalStatus()
            } else if (statusNotFound) {
              navigate('/personalinformation')
            }
          }}
          disabled={indexRecharge === -1 || idFacture === ''}
        >
          Suivant
        </button>
      </div>
      <ModalTestStatusUser
        isOpen={openIsModalStatus}
        closeModal={closeModalStatus}
        statusPending={statusPending}
        statusNotFound={statusNotFound}
      />
    </div>
  )
}

export default FacturePaid
