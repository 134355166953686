import BenificePerson from 'components/BenificePerson'
import RightDash from 'components/RightDash'
import UserContext from 'contexts/UserContext'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getListBeneficary } from 'requests/benificary'
import { TransactionsList } from '../../requests/transfer'

import pointExclamation from '../../assets/pointExclamationIcon.svg'
import iconHomePage from '../../assets/iconHomePage.svg'


import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
import iconAddBnf from '../../assets/iconAddBnf.svg'
import ModalBeneficiary from 'containers/beneficiaireContainer/modalBeneficiary'
import ModalAddBeneficiary from 'containers/beneficiaireContainer/modalAddBeneficiary'
import ContainerHome from 'containers/containerHome/containerHome'

interface PersonalInfo {
  firstName: string
  lastName: string
  phone: {
    prefix: string
    number: string
  }
  avatar?: number
  gender?: 'male' | 'female'

  recipient?: {
    avatar: 1
    personalInfo: {
      firstName: 'string'
    }
  }
}

interface Beneficiary {
  _id?: string
  personalInfo?: PersonalInfo
  lastTransferDate?: string
}

const DetailsUserContainer = () => {
  const { user } = useContext(UserContext)

  const { TransferData, setTransferData, setTransferDataBeneficiary, TransferDataBeneficiary } =
    useContext(transferBeneficiaryContext)
  const [isAdded, setIsAdded] = useState(false)
  const [indexBeneficiary, setIndexBeneficiairy] = useState(-1)
  const { setBeneficiaries } = useContext(BeneficiaryContext)
  // const { TransferData, setTransferData,} = useContext(transferBeneficiaryContext)
  const [person, setPerson] = useState<any[]>([])
  const [transactionData, setTransactionData] = useState<any>([])

  const [isModalAddBenefOpen, setIsModalAddBenefOpen] = useState(false)

  const closeModal = () => {
    setIsModalAddBenefOpen(false)
  }

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      if (user?.user?._id && user?.token?.accessToken) {
        try {
          const response = await getListBeneficary(user.user._id, user.token.accessToken)
          const responseT = await TransactionsList(user.user._id, user.token.accessToken)
          if (response?.success === true) {
            setPerson(response?.beneficiaries)
          }
          if (responseT?.success === true) {
            setTransactionData(responseT.transfers)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
    fetchBeneficiaries()
  }, [user, isAdded])

  // const handleClick = (index: number, beneficiary: Beneficiary) => {
  //   // console.log('index', index)
  //   // console.log('beneficiary',beneficiary)
  //   setIndexBeneficiairy(index)
  //   // setSelectedBeneficiary(beneficiary)

  //   // console.log('lastName',selectedBeneficiary?._id)
  //   setIsopenModal(true)

  // }
  const closeModalAddBeneficiary = () => {
    // (false)
    setIsModalAddBenefOpen(false)
  }
  const OpenModalAddBeneficiary = () => {
    setIsModalAddBenefOpen(true)
  }

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await getListBeneficary(id, token)
        if (response?.success === true) {
          setTransferDataBeneficiary(response?.beneficiaries)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (user) {
      fetchData()
    }
  }, [user])
  useEffect(() => {
    async function fetchData() {
      setPerson(TransferDataBeneficiary ? TransferDataBeneficiary : [])
    }

    if (TransferDataBeneficiary) {
      fetchData()
    }
  }, [TransferDataBeneficiary])
  const addBeneficiary = (newBeneficiary: any) => {
    setPerson((prev) => [...prev, newBeneficiary])// Ajoute le nouveau bénéficiaire à la liste actuelle
  }
 
  return (
    // <div>
    <div className="shadow w-[320px] pb-5 lg:hidden 2xl:block px-[6%]">
      <RightDash />
      <div className="text-center">
        {user?.user?.status === 'pending' && !user?.user?.isDocumentSent ? (
          <Link
            to="/personalinformation"
            className="text-[#E84148] flex items-center text-[15px] lg:text-[13px] font-medium whitespace-nowrap"
          >
            <img src={pointExclamation} className="w-6 h-6 " />
            <span>Veuillez compléter vos informations !</span>
          </Link>
        ) : (
          ''
        )}
        {user?.user?.status === 'pending' && user?.user?.isDocumentSent ? (
          <span className="text-orange-500 text-[15px] lg:text-[13px] font-medium">
            Votre compte est en cours de validation
          </span>
        ) : (
          ''
        )}
        {user?.user?.status === 'confirmed' && user?.user?.isDocumentSent ? (
          <div className="text-green-500 text-[15px] lg:text-[13px] font-medium mt-3 text-center">
            Félicitations, vous pouvez envoyer de l&apos;argent
          </div>
        ) : (
          ''
        )}
      </div>
      {transactionData.length === 0 ? (
        ''
      ) : (
        <div>
          <div className="flex justify-between  items-center w-full mt-6">
            <h2 className="font-bold text-[18px] lg:text-[13px]">Récemment envoyé</h2>
            <Link
              className={`${person && person.length === 0 ? 'hidden' : 'block'} text-[#B4B4B4] text-[16px] lg:text-[12px]`}
              to={'/History'}
            >
              Voir tout
            </Link>
          </div>
        

          <div className="flex mt-4 gap-[5%]">
            {transactionData &&
              transactionData.slice(0, 4).map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    setBeneficiaries(item)
                    setTransferData({
                      ...TransferData,
                      BeneficiaryName: `${item?.recipient?.personalInfo?.firstName} ${item?.recipient?.personalInfo?.lastName}`,
                      idBeneficiary: item?._id,
                      Motif: '',
                    })
                  }}
                >
                  <BenificePerson
                    nom={`${item?.recipient?.personalInfo?.firstName} ${item?.recipient?.personalInfo?.lastName}`}
                    num={`${
                      item?.recipient?.personalInfo?.phone.prefix === '212'
                        ? '+212'
                        : item?.recipient?.personalInfo?.phone.prefix === '216'
                          ? '+216'
                          : item?.recipient?.personalInfo?.phone.prefix
                    }
                        ${item?.recipient?.personalInfo?.phone?.number}`}
                    photo={item.recipient?.avatar}
                    genre={item.recipient?.personalInfo?.gender}
                    index={item?._id}
                    person={item}
                    setIndexBeneficiairy={setIndexBeneficiairy}
                    indexBeneficiary={indexBeneficiary}
                    item={item}
                    lastName={item?.recipient?.personalInfo?.lastName}
                    prefix={item?.recipient?.personalInfo?.phone.prefix}
                    isClickable = {false}
                  />
                </div>
              ))}
            {/* <img
              src={iconAddBnf}
              onClick={OpenModalAddBeneficiary}
              width={45}
              height={45}
              className="object-cover cursor-pointer"
            /> */}
            <div className="cursor-pointer " onClick={OpenModalAddBeneficiary}>
              <img src={iconAddBnf} className=" object-cover" width={45} height={45}  />
              <div 
              className="text-sm text-[#5754E5]">Ajouter</div>
            </div>
           

            <ModalBeneficiary isOpen={isModalAddBenefOpen} closeModal={closeModalAddBeneficiary}
            
             >
              <ModalAddBeneficiary
                // setBeneficiaryData={setPerson}
                closeModal={closeModal}
                // newImage={newImage}
                // setNewImage={setNewImage}
                // setNewImageSelected={setNewImageSelected}
                setIsAdded={() => setIsAdded(!isAdded)}
                // selectedImageId={selectedImageId}
                // setSelectedImageId={setSelectedImageId}
                // dataAvatar={dataAvatar}
 
              />
            </ModalBeneficiary>
          </div>
        </div>
      )}

      <div className="mt-6 flex flex-col gap-4 ">
        <div className={`flex justify-between items-center`}>
          <div className="text-[18px] lg:text-[13px] font-bold">Mes Bénéficiaires</div>
          <Link
            className={`${person && person.length === 0 ? 'hidden' : 'block'} text-[#B4B4B4] text-[16px] lg:text-[12px]`}
            to={'/beneficiary'}
          >
            Voir tout
          </Link>
        </div>
        {person && person.length === 0 ? (
          <div className="border-dotted border-2 border-[#5754E5] rounded-lg p-12 gap-8 flex flex-col items-center justify-center">
            <img src={iconHomePage} width={48} height={53} />
            <div className="tetx-[#6B6B6B] font-medium text-[14px] text-center">
              Vous n&lsquo;avez pas encore ajouté de bénéficiaire.
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-[24px]">
            {person &&
              person.slice(0, 4).map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setBeneficiaries(item)
                    setTransferData({
                      ...TransferData,
                      BeneficiaryName: `${item?.personalInfo?.firstName} ${item?.personalInfo?.lastName}`,
                      idBeneficiary: item?._id,
                      Motif: '',
                    })
                  }}
                >
                  <BenificePerson
                    nom={`${item?.personalInfo?.firstName} ${item?.personalInfo?.lastName}`}
                    num={`${
                      item?.personalInfo?.phone.prefix === '212'
                        ? '+212'
                        : item?.personalInfo?.phone.prefix === '216'
                          ? '+216'
                          : item?.personalInfo?.phone.prefix
                    }
                      ${item?.personalInfo?.phone.number}`}
                    genre={item?.personalInfo?.gender}
                    photo={item.avatar}
                    index={item?._id}
                    person={item}
                    setIndexBeneficiairy={setIndexBeneficiairy}
                    indexBeneficiary={indexBeneficiary}
                    item={item}
                    afficheName={true}
                    
                    prefix={item?.personalInfo?.phone.prefix}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
    // </div>
  )
}

export default DetailsUserContainer
