import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import * as yup from 'yup'

import VisibleEye from '../../assets/visibleEye.svg'
import InvisibleEye from '../../assets/invisibleEye.svg'
import { useFormik } from 'formik'
import UserContext from 'contexts/UserContext'
import { updatePassword } from 'requests/user'
import ModalUpdatePassword from 'components/modalUpdatePassword'

export const UpdateProfile = () => {
  const [visible, setVisible] = useState(false)
  const [visibleForgotPassword, setVisibleForgotPassword] = useState(false)
  const [visibleConfirmed, setVisibleConfirmed] = useState(false)
  const [passwordSuccess, setPasswordSuccess] = useState(false)
  const [openModalSuccessPassword, setOpenModalSuccessPassword] = useState(false) 

  const { user } = useContext(UserContext)

  const openModalPassword = () => {
    setOpenModalSuccessPassword(true)
  }

  const closeModalPassword = () => {
    setOpenModalSuccessPassword(false)
  }

  const initialFormData = {
    ancienMotDePass: '',
    NouveauMotDePass: '',
    ConfirmerMotDePass: '',
  }
  const [formData, setFormData] = useState(initialFormData)
  const validationSchema = yup.object({
    ancienMotDePass: yup.string().required('Ce champ est requis'),
    NouveauMotDePass: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z\d\s]).{8,16}$/,

        'Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère spécial.',
      )
      .required('Ce champ est requis'),
    ConfirmerMotDePass: yup
      .string().required('Ce champ est requis')
      .oneOf([yup.ref('NouveauMotDePass')], 'Les mots de passe ne correspondent pas')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z\d\s]).{8,16}$/,
        'Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère spécial.',
      )
      ,
  })
  const handleSubmit = (values: typeof initialFormData) => {    
    if (formik.isValid) {
      const updatePasswordUser: any = {
        ancienMotDePass: values.ancienMotDePass,
        NouveauMotDePass: values.NouveauMotDePass,
        ConfirmerMotDePass: values.ConfirmerMotDePass,
      }
      setFormData(initialFormData)

      updatePassword(user?.user?._id ,user?.token?.accessToken , updatePasswordUser.ancienMotDePass,updatePasswordUser.NouveauMotDePass)
      .then((response: any) => {
        if (response.success) {
          openModalPassword()
          setPasswordSuccess(true)
        } 
        else{
          openModalPassword()

          setPasswordSuccess(false)
        }
      })
    }
  }
  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <div className="sm:ml-[15px] w-[500px] sm:w-[320px]">
      <div className="mt-8 w-full">
        <div className="text-[15px] px-2 font-bold text-[#6B7280]">Mot de passe actuel</div>
        <div className="h-[100px]">
        <div className="relative items-center flex mt-8 ">
              <input
                className="bg-[#F9FAFB] pl-[14px] py-4 rounded-xl w-[70%] lg:w-[320px]  placeholder:text-[#9CA3AF] text-[#5754E5]"
                name="ancienMotDePass"
              placeholder="Mot de passe actuel"
              type={visible ? 'text' : 'password'}
              value={formik.values.ancienMotDePass}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur('ancienMotDePass')}
            />
            <img
              src={visible ? VisibleEye : InvisibleEye}
              alt=""
              className={classNames(
                'absolute right-40  lg:right-48 sm:right-4'
              )}
              onClick={() => setVisible(!visible)}
            />
           
          </div>
          {formik.errors.ancienMotDePass && formik.touched.ancienMotDePass && (
            <div className="text-red-500 text-[14px] pl-2">{formik.errors.ancienMotDePass}</div>
          )}
        </div>
      </div>
      {/* <div className="sm:text-justify text-[#A7A9B7] text-[15px] font-bold"> Mot de passe oublié ?</div> */}

      <div className="mt-8">
        <div className="text-[15px] px-2 font-bold text-[#6B7280]">Nouveau mot de passe</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="h-[140px]">
            <div className="relative items-center flex mt-8">
              <input
                className="bg-[#F9FAFB] pl-[14px] py-4 rounded-xl w-[70%] lg:w-[320px]  placeholder:text-[#9CA3AF] text-[#5754E5]"
                name="NouveauMotDePass"
                placeholder="Nouveau mot de passe"
                type={visibleForgotPassword ? 'text' : 'password'}
                value={formik.values.NouveauMotDePass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur('NouveauMotDePass')}
              />
              <img
                src={visibleForgotPassword ? VisibleEye : InvisibleEye}
                alt=""
                className={classNames(
                  'absolute right-40  lg:right-48 sm:right-4'
                )}
                onClick={() => setVisibleForgotPassword(!visibleForgotPassword)}
              />
             
            </div>
            {formik.errors.NouveauMotDePass && formik.touched.NouveauMotDePass && (
              <div className="text-red-500 text-[14px] pl-2">{formik.errors.NouveauMotDePass}</div>
            )}
          </div>

          <div className="h-[120px]">
            <div className="relative items-center flex mt-3">
              <input
                className="bg-[#F9FAFB] pl-[14px] py-4 rounded-xl w-[70%] lg:w-[320px]  placeholder:text-[#9CA3AF] text-[#5754E5]"
                name="ConfirmerMotDePass"
                placeholder="Confirmer le nouveau mot de passe"
                type={visibleConfirmed ? 'text' : 'password'}
                value={formik.values.ConfirmerMotDePass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur('ConfirmerMotDePass')}
              />
              <img
                src={visibleConfirmed ? VisibleEye : InvisibleEye}
                alt=""
                className={classNames(
                  'absolute right-40  lg:right-48 sm:right-4'
                )}
                onClick={() => setVisibleConfirmed(!visibleConfirmed)}
              />
             
            </div>
            {formik.errors.ConfirmerMotDePass && formik.touched.ConfirmerMotDePass && (
              <div className="text-red-500 text-[14px] pl-2">{formik.errors.ConfirmerMotDePass}</div>
            )}
          </div>
              <ModalUpdatePassword isOpen={openModalSuccessPassword} closeModal={closeModalPassword} passwordSuccess={passwordSuccess} />
          <button
            value="submit"
            type="submit"
            className="bg-[#4743C9] text-white text-[15px] sm:mb-5 font-bold w-[70%] lg:w-[320px] p-3 rounded-xl"
          >
            Valider
          </button>
        </form>
      </div>
    </div>
  )
}
