import React from 'react'
import imageHome from '../../assets/imageHome.svg'
import imagePaimentHomeGroupe from '../../assets/imgCodePaiementHomePage.svg'
import { Link } from 'react-router-dom'
import appStoreIcon from '../../assets/AppleStore.svg'
import playStoreIcon from '../../assets/GoogleStore.svg'
import newImageHomePage from '../../assets/newImageHomePage2.svg'
import imageSecondHomeResponsive from '../../assets/imageSecondHomeResponsive.svg'

const ImageHomeContainer = () => {
  
const HandleClick=(url:string)=>{
  window.open(url, '_blank')
}
  return (
    <div className='bg-[#E2E2FB] h-[240px] sm:h-[150px] rounded-xl flex justify-between'>
      <div className='grid justify-items-stretch w-full'>
        <img className='justify-self-end -translate-y-5 sm:hidden' width={150} height={60} src={imagePaimentHomeGroupe} alt=''/>
          <div className='pl-3 my-auto sm:pl-2 -translate-y-16 sm:-translate-y-0 '>
            <h1 className='font-bold text-[50px] 4xl:text-[40px] sm:text-[20px] pl-4 sm:pl-0'>My Easy Transfer</h1>
            <h2 className='text-[#898795] text-[18px] w-[420px] 3xl:w-[370px] pl-4 sm:pl-0 sm:w-full sm:text-[15px] sm:mt-1 '>Envoyez votre argent quand vous voulez où vous voulez!</h2>
            <div className='flex gap-4 items-center mt-6 sm:mt-3'>
              <img className=' rounded-xl sm:w-[80px] sm:h-[30px] cursor-pointer' width={130} height={60} src={appStoreIcon} alt='' onClick={()=>{HandleClick('https://apps.apple.com/ci/app/my-easy-transfer/id1572782943')}}/>
              <img  className='rounded-xl sm:w-[80px] sm:h-[30px] cursor-pointer' width={130} height={60}  src={playStoreIcon} alt='' onClick={()=>{HandleClick('https://play.google.com/store/apps/details?id=com.myeasytransfert&hl=en_US')}}/>
            </div>
          </div>
      </div>
        <img className='hidden sm:block h-[181px] -translate-y-8 '  width={150} height={240} src={imageSecondHomeResponsive} alt=''/>
        <img className='-translate-y-16 h-[305px] 3xl:h-[352px] 3xl:-translate-y-28  3xl:w-[200px] sm:hidden'    src={newImageHomePage} alt='accuiel my easy'/> 

    </div>
    
  )
}

export default ImageHomeContainer
