import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import localforage from 'localforage'
import Accueil from './icons/HomeIcon'
import Historique from './icons/HistoriqueIcon'
import Parrainage from './icons/ParrainageIcon'
import TransfertIcon from './icons/TransfertIcon'
import RechargeIcon from './icons/RechargeIcon'
import BeneficiareIcon from './icons/BeneficiareIcon'
import InviteFriends from '../assets/invite.svg'
import Settings from '../assets/setting.svg'
import SignOut from '../assets/signOut.svg'
import UserContext from 'contexts/UserContext'
import SignOutIcon from '../assets/SignOutIcon.svg'
import frameDashboard from '../assets/frameDashboard.svg'
import imageAccount from '../assets/imageAccount.svg'
import ModalLogOutContainer from 'containers/logOutContainer/modalLogOutContainer'
import AssuranceIcon from './icons/AssuranceIcon'
import PaimentFactureIcon from './icons/PaimentFactureIcon'
import TransfertCompteBancaireIcon from './icons/TransfertCompteBancaireIcon'
import BeneficiaryIcon from './icons/BeneficiaryIcon'
import AssuranceIconBlue from './icons/AssuranceIconBlue'
import HistoriqueIcon from './icons/HistoriqueIcon'

export default function SideBar() {
  const location = useLocation()
  const navigate = useNavigate()
  const { setUser } = useContext(UserContext)
  const[openModalLogOut, setOpenModalLogOut] = useState(false)
  const [activeLink, setActiveLink] =useState(location.pathname)
   const [isLinkDefault, setIsLinkDefault] = useState(false)
   
  useEffect(() => {
    if( (location.pathname === '/') ){
      setIsLinkDefault(true)
    }
    else{
      setActiveLink(location.pathname)
      setIsLinkDefault(false)
    }
  }, [location])

  // const navigateToSettingProfile = () => {
  //   navigate('/settingProfile', { state: isLink })
  //   console.log(isLink)
  // }

  const closeModal = () =>{
    setOpenModalLogOut(false)
  }
 
  const Links: {
    img: any
    name: string
    link: string
  }[] = [
    {
      img: <Accueil  isActive={isLinkDefault}/>,
      name: 'Accueil',
      link: '/',
    },
    {
      img: <HistoriqueIcon  isActive={location.pathname === '/History'}/> ,
      name: 'Historique',
      link: '/History',
    },

    {
      img: <RechargeIcon isActive={location.pathname === '/recharge'} />,
      
      name: 'Recharge mobile',
      link: '/recharge',
    },
    {
      img: <TransfertIcon isActive={location.pathname === '/transfert/0'}/>,
 
      name: "Transfert d'argent avec retrait en cash",
      link: '/transfert/0',
    },

    {
      img: <TransfertCompteBancaireIcon isActive={location.pathname === '/transfert/1'}/>,
     
      name: "Transfert d'argent vers un compte bancaire",
      link: '/transfert/1',
    },
    // {
    //   img: <PaimentFactureIcon fill="#8F8F8F" />,
    //   secondImg: <PaimentFactureIcon fill="#5754E5" />,
    //   name: "Paiement de factures locales",
    //   link: '/paimentFacture',
    // },
    // {
    //   img: <AssuranceIcon fill="#8F8F8F" />,
    //   secondImg: <AssuranceIconBlue fill="#5754E5" />,
    //   name: "Souscription assurance locale",
    //   link: '/assurance',
    // },
    {
      img: <BeneficiaryIcon isActive={location.pathname === '/beneficiary'}/>,
      name: 'Bénéficiaires',
      link: '/beneficiary',
    },
  ]
 

  return (
    <div className="pl-[43px]  lg:hidden w-[300px]">
      <div className=" flex flex-col gap-[34px] ">
        {Links.map((item, index) => (
          <Link
          to={item.link}
          key={index}
          className="flex gap-[16px] items-center transition duration-200 group"
        >
          <div className="flex gap-4 items-center">
            <div>
              {item.img}
            </div>
            {/* Nom avec hover basé sur le conteneur */}
            <div
              className={classNames(
                location.pathname === item.link ? 'text-[#5754E5]' : 'text-[#A5A5A5]',
                'group-hover:text-[#4D36CC] text-[17px] font-semibold',
              )}
            >
              {item.name}
            </div>
          </div>
        </Link>
        ))}
      </div>

      <div className="mt-[2rem] mb-3 hover:cursor-pointer">
        <img className="" src={frameDashboard} onClick={()=>{navigate('/settingProfile/1')}}/>
      </div>

      <div
        className=" flex gap-[16px] items-center my-[44px] hover:cursor-pointer"
         onClick={() => {setOpenModalLogOut(true)}}>
        <img src={SignOutIcon} alt="" />
        <div className="text-[#5754E5] text-[17px]  font-semibold">Déconnexion</div>
      </div>
      <ModalLogOutContainer isOpen={openModalLogOut} closeModal={closeModal} openModalLogOut={openModalLogOut}/>
    </div>
  )
}

