import React, { useContext} from 'react'
import UserContext from 'contexts/UserContext'

import newDefaultImageHomme from '../assets/newDefaultImageHomme.svg'
import newDefaultImageFemme from '../assets/newDefaultImageFemme.svg'

const RightDash: React.FC = () => {
  const { user } = useContext(UserContext)
// console.log(user?.user?.status)
// console.log(user?.user?.isDocumentSent)

  return (
    
      <div className="flex flex-col items-center gap-3 ">
        <div className="rounded-full">
          <img
          height={100}
          width={100}
            className={`border-4 rounded-full  
            ${
              user?.user?.status === 'pending' && !user?.user?.isDocumentSent ? 'border-[#EC1C24]' : ''
            }
            ${
              user?.user?.status === 'pending' && !user?.user?.isDocumentSent ? 'border-[#FF9C00]' : ''
            }
            ${
              user?.user?.status === 'confirmed' &&  !user?.user?.isDocumentSent ?'border-[#20C374]' : ''
            }
            `}
            src={user?.user?.personalInfo?.gender === 'female' ? newDefaultImageFemme : newDefaultImageHomme}
          />
        </div>
          <div className="text-[15px] lg:text-[13px] text-center font-bold ">
            {user?.user?.personalInfo?.firstName}{' '}{user?.user?.personalInfo?.lastName}
          </div>

        <div className="text-[#B4B4B4] text-[15px] lg:text-[13px] font-light">{user?.user?.email}</div>
      </div>
  
  )
}

export default RightDash
