import classNames from 'classnames'
import React from 'react'
import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'

interface Props {
    logo: string
    montantEnvoyer : string
    montanPayer : string
    bgCatre : string
    colorText? : string
    On? : boolean
    indexRecharge?: number
    index: number
    widhtLogo? : string

}

const carteFact : React.FC<Props> = ({logo,montantEnvoyer,montanPayer, bgCatre,colorText='text-[#FFFFFF]',indexRecharge, On, index, widhtLogo }) => {
  return (
    <div className={classNames(bgCatre,colorText,' rounded-lg p-3 w-[300px] cursor-pointer')}>
        <div className='flex items-center justify-between'>
            <img  className={classNames( widhtLogo,'')} src={logo} alt='logo' width={30} height={30} />
            <div className="relative items-center">
                <img src={RadioFocus} className="w-6 h-6" />
                <img
                    src={RadioON}
                    className={classNames(
                    On && indexRecharge === index ? 'absolute inset-[25%] w-3 h-3' : 'hidden',
                    )}
                />
            </div>        

        </div>
        <div className='font-extralight text-[12px] mt-2 '>Montant de la facture</div>
        <div className={classNames( 'flex  gap-5 justify-between')}>
            <div className={classNames('flex font-bold gap-1')}>
                <div className={classNames('text-[30px]')}>
                    {montantEnvoyer}
                </div>
                <span className='text pt-[6px]'>TND</span>
            </div>
            <div className='flex-col items-center text-center w-[100px]  pt-[6px]'>
                <div className='font-extralight text-[12px]  '>Montant à payer</div>
                <div className='text-[18px] text-center font-bold '>
                    {Number(montanPayer).toFixed(3)}<span> €</span>
                </div>
                <div className=' text-[10px]'>Inclus 1.5 € de frais</div>
            </div>

        </div>
    </div>
  )
}

export default carteFact
