import React, { useContext, useEffect, useRef, useState } from 'react'
import iconSelectInput from '../../assets/iconSelectInput.svg'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
import useClickOutside from '../../hooks/useOnclickOutside'
import RadioON from '../../assets/radioON.svg'
import RadioFocus from '../../assets/radioFocus.svg'

interface Props {
  tableauNetwork: any
  setColectPointId: (value: any) => void
  setReseaux: (value: string) => void
  selectedOptionPointCollecte: any
  setSelectedOptionPointCollecte: (value: string) => void
  euroValue: any
  reseaux: string
  previousId: any
  saveIdGov: any
  colectPointId: string
  selectedOptionReseaux: string
  setSelectedOptionReseaux: any
  getListPartenaireByCity: any
  getListCity: any
}

const partnerCard: React.FC<Props> = ({
  tableauNetwork,
  setColectPointId,
  setReseaux,
  selectedOptionPointCollecte,
  setSelectedOptionPointCollecte,
  euroValue,
  reseaux,
  previousId,
  saveIdGov,
  colectPointId,
  selectedOptionReseaux,
  setSelectedOptionReseaux,
  getListPartenaireByCity,
  getListCity,
}) => {
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)
  const [indexCard, setIndexCard] = useState(-1)
  const [isOpenReseaux, setIsOpenReseaux] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [isOpenPointCollecte, setIsOpenPointCollecte] = useState(false)

  const [isNetworkSaved, setIsNetworkSaved] = useState('')
  const [isClic, setIsClic] = useState(false)

 

  const divRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)

  const toggleSetting = () => {
    setIsOpenReseaux(false), setIsOpenPointCollecte(false)
  }

  useClickOutside(toggleSetting, dropDownRef, divRef)

  //get List Partenaire By City

  const filteredPartenaire = getListPartenaireByCity.filter((item: any) =>
    getListCity.some((city: any) => city === item.address.city),
  )
  
  const handleSelect = (option: any) => {
    setSelectedOption(option.value)
  }

  const handleSelectReseaux = (genre: any) => {
    setSelectedOptionReseaux(genre)
  }

  const handleSelectPoint = (option: any) => {
    setSelectedOption(option.value)
  }

  const handleSelectPointCollecte = (genre: any) => {
    setSelectedOptionPointCollecte(genre)
  }
  useEffect(() => {
    if (TransferData) {
      setSelectedOptionReseaux(TransferData?.partenaire || '')
      //setIsNetworkSaved(TransferData?.partenaire || '')
      setReseaux(TransferData?.partenaire === 'Swared' ? 'Viamobile' : TransferData?.partenaire || '')
      setSelectedOptionPointCollecte(TransferData?.pointDeCollect || '')
      setColectPointId(TransferData?.colectPointId)
    }
  }, [])

  const handleClickOutside = (event: any) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpenReseaux(false)
      setIsOpenPointCollecte(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (
      euroValue > 1000 &&
      TransferData?.partenaire !== 'izi' &&
      TransferData?.partenaire !== 'Wafacash' &&
      getListPartenaireByCity.filter((item: any) => item.network !== 'izi' && item.network !== 'Wafacash')
    ) {
      setSelectedOptionPointCollecte('')
      setSelectedOptionReseaux('')
    }
  }, [euroValue])
  return (
    <div>
      <div className="grid grid-cols-1 mt-5 sm:justify-center 2sm:w-[340px]" ref={dropDownRef}>
        <label className="sm:mx-0 md:mx-0 2sm:ml-6">
          <div className="flex w-full">
            <div className="relative w-full inline-block mt-3 hover:cursor-pointer" ref={dropDownRef}>
              <input
                type="text"
                className="p-2 border font-bold hover:cursor-pointer text-[15px] sm:h-[40px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#1D3A70]"
                placeholder="Partenaire"
                onClick={() => setIsOpenReseaux(!isOpenReseaux)}
                value={
                  reseaux !== ''
                    ? selectedOptionReseaux.toLowerCase() === 'viamobile'
                      ? 'Swared'
                      : selectedOptionReseaux.charAt(0).toUpperCase() + selectedOptionReseaux.slice(1).toLowerCase()
                    : 'Partenaire'
                }
                autoComplete="off"
                // onChange={(e) => {
                //   handleSelect(e.target.value)
                //   setSelectedOptionPointCollecte('')
                // }}
                name="selectedOptionReseaux"
                readOnly
              />
              <div
                className="absolute top-0 right-0 mt-[15px] sm:mt-[10px] 2sm:mt-[10px] mx-3"
                onClick={() => setIsOpenReseaux(true)}
              >
                <img src={iconSelectInput} className="h-6 w-6" />
              </div>

              <div
                className={`absolute top-full left-0 z-10 min-h-auto max-h-[150px] rounded-lg w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
                  isOpenReseaux ? 'block' : 'hidden'
                }`}
              >
                {
                  // Array.from(new Set(filteredPartenaire.map((item: any) => item?.network)))
                  //   .map((uniqueNetwork) => {
                  //     let selectedNetwork: any
                  //     if (uniqueNetwork === 'Wafacash') {
                  //       selectedNetwork = 'Wafacash'
                  //     } else if (selectedNetwork === 'Viamobile' || selectedNetwork === 'viamobile') {
                  //       selectedNetwork = 'Viamobile'
                  //     } else {
                  //       selectedNetwork = uniqueNetwork
                  //     }
                  //     if (euroValue > 1000 && selectedNetwork === 'Viamobile' || selectedNetwork === 'viamobile') {
                  //       return null
                  //     }
                  //     return selectedNetwork
                  //   })
                  //   .filter((value, index, self) => self.indexOf(value) === index)
                  Array.from(new Set(filteredPartenaire.map((item: any) => item.network.toLowerCase()))).map(
                    (selectedNetwork: any, index: any) => (
                      <div
                        key={index}
                        className={`cursor-pointer p-2 hover:bg-gray-100`}
                        onClick={() => {
                          handleSelectReseaux(selectedNetwork)
                          //setColectPointId(tableauNetwork.find((item: any) => item.network === selectedNetwork)?._id)
                          setReseaux(selectedNetwork)
                          //setIsNetworkSaved(selectedNetwork)
                          setIndexCard(index)
                          setTransferData({ ...TransferData, partenaire: selectedNetwork })
                          if (selectedNetwork !== reseaux) {
                            setSelectedOptionPointCollecte('')
                            setTransferData({ ...TransferData, partenaire: selectedNetwork, pointDeCollect: '' })
                          }
                        }}
                      >
                        <div className="text-[#1D3A70] text-[15px] font-semibold">
                          {selectedNetwork.toLowerCase() === 'viamobile'
                            ? 'Swared'
                            : selectedNetwork.charAt(0).toUpperCase() + selectedNetwork.slice(1).toLowerCase()}
                        </div>
                      </div>
                    ),
                  )
                }
              </div>
            </div>
          </div>
        </label>

        <label className="sm:mx-0 md:mx-0 2sm:ml-6 h-[120px] sm:h-[90px]">
          <div className="flex flex-col w-full">
            <div className="relative inline-block mt-8 hover:cursor-pointer" ref={dropDownRef}>
              <input
                type="text"
                className="p-2  w-full h-[56px] hover:cursor-pointer border sm:h-[40px] font-bold text-[15px] rounded-xl text-[#1D3A70] placeholder:text-[#1D3A70]"
                placeholder="Point de collecte"
                onClick={() => setIsOpenPointCollecte(!isOpenPointCollecte)}
                value={selectedOptionPointCollecte !== '' ? selectedOptionPointCollecte : 'Point de collecte'}
                autoComplete="off"
                // onChange={(e) => {
                //   handleSelectPoint(e.target.value)
                // }}
                name="selectedOptionPoint"
                readOnly
              />
              <div
                className="absolute top-0 right-0 mt-[15px] sm:mt-[10px] 2sm:mt-[10px] mx-3"
                // onClick={() => setIsOpenPointCollecte(!isOpenPointCollecte)}
              >
                <img src={iconSelectInput} className="h-6 w-6" />
              </div>

              <div
                className={`absolute bottom-full z-10 min-h-auto max-h-[160px] rounded-lg w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto  
                ${isOpenPointCollecte ? 'block' : 'hidden'}`}
              >
                {filteredPartenaire
                  ?.filter((pt: any) => pt?.network.toLowerCase() === reseaux.toLowerCase())
                  .map((item: any, index: number) => (
                    <div
                      key={index}
                      className="py-4 px-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        // setIsClic(!isClic)
                        handleSelectPointCollecte(`${item?.storeInfo?.name} `),
                          setColectPointId(item?._id)
                        setTransferData({
                          ...TransferData,
                          pointDeCollect: `${item?.storeInfo?.name}`
                          //   ${item?.address?.street}`,
                          // colectPointId: item?._id,
                        })
                      }}
                    >
                       <div className='flex item center gap-2'>
                       {/* <div className=' relative' 
                        onClick={()=> 
                        {
                          setIsClic(!isClic)
                          setIndexCard(index)
                        }}
                        > */}
                        <div className=' my-auto flex relative  w-[20px] h-[20px]'>
                          <img className=' ' src={RadioFocus} alt="radio" width={20} height={20} />
                          {colectPointId === item?._id && <img className=' absolute  ' src={RadioON} alt='radio' width={20} height={20}/>}
                        </div>

                        <div
                          className="text-[#1D3A70] text-[14px] font-semibold "
                        >
                          {item?.storeInfo?.name}<br/> 
                          Téléphone : {item?.storeInfo?.phoneNumber}<br/>  
                          Adresse : {item?.address?.street} 
                        </div>
                      </div>
                     </div>
                  ))}
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  )
}

export default partnerCard
