import classNames from "classnames"
import React from "react"
import { useNavigate } from "react-router-dom"
import iconPageTransfertStatus from '../assets/iconPageTransfertStatus.svg'

interface props{
    isOpen: boolean
    closeModal: () => void
    statusPending:any
    statusNotFound:any
}
const ModalTestStatusUser : React.FC<props> = ({isOpen, closeModal, statusPending, statusNotFound})=>{
    
    if (!isOpen) return null
    const navigate = useNavigate()
  
 return (
    <div className={classNames('justify-center items-center flex fixed inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm')}>
      <div className="relative my-6 mx-auto max-w-3xl">
        <div className={classNames('border-0 rounded-xl pb-8 pt-5 px-[10rem] sm:px-8 md:px-8 sm:py-8 md:py-8 shadow-xl relative xl:py-4 flex flex-col w-full bg-white')}>
          <div className="flex flex-col justify-center items-center mt-10">
            <img src={iconPageTransfertStatus} className="w-[100px] h-[100px] mb-[40px]" />
            <div className="text-[#1D3A70] text-[20px] sm:text-[20px] md:text[20px] font-semibold pb-1 text-center">
                {statusPending && 
                  <div>
                    Nous avons bien reçu votre pièce d&apos;identité. <br/> Nous allons vous notifier par e-mail après l&apos;avoir validée.
                  </div>
                } 
            </div>
            <button
              onClick={()=>{
                if(statusPending){
                  closeModal()
                  navigate('/')
                }
              }}
              className={`rounded-xl h-[50px] bg-[#1D3A70] mt-8 text-white text-[25px] w-full font-medium`}>
               ok
            </button>
          </div>
        </div>
      </div>
    </div>
 )
}

export default ModalTestStatusUser