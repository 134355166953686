import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Spinner } from 'utils/loading'
import UserContext from 'contexts/UserContext'
import { getAssurance, payedInssurance } from 'requests/assurance'
import { io } from 'socket.io-client'
import ModalTestStatusUser from 'components/modalTestStatusUser'
import { useNavigate } from 'react-router-dom'

interface props {
  setNumStep: any
  functionTracks: any
  setClientSecret: any
  setInssuranceId: any
}
const Inscription: React.FC<props> = ({ setNumStep, functionTracks, setClientSecret, setInssuranceId }) => {
  const { user } = useContext(UserContext)

  const statusComplete = user?.user?.status === 'confirmed' && user?.user?.isDocumentSent
  const statusPending = user?.user?.status === 'pending' && user?.user?.isDocumentSent
  const statusNotFound = user?.user?.status === 'pending' && !user?.user?.isDocumentSent
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState('')
  const [openIsModalStatus, setOpenIsModalStatus] = useState(false)
  const openModalStatus = () => {
    setOpenIsModalStatus(true)
  }
  const closeModalStatus = () => {
    setOpenIsModalStatus(false)
  }
  const getURL = async () => {
    const id = user?.user?._id

    try {
      const response = await getAssurance(id)
      if (response?.success === true) {
        setUrl(response?.url)
        const timer = setTimeout(() => {
          setLoading(false)
        }, 3000)

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getURL()
  }, [user])

  const paiementInssurance = async (dataInssurance: any) => {
    const id = user?.user?._id
    const token = user?.token
    const data = {
      userId: dataInssurance?.userId,
      amount: dataInssurance?.amount,
      currency: dataInssurance?.currency,
      reason: 'Maghrebia Payment',
      id: dataInssurance?.id,
      platform: 'web'
    }

    try {
      const response = await payedInssurance(data, token)
      if (response?.success === true) {
        setClientSecret(response?.client_secret)
        setInssuranceId(response?.payment_id)
      } else {
        console.log('Response failed payed Payment', response)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    // Start the background task for maintaining the Socket.IO connection

    const StartSocketConnectionTask = async () => {
      try {
        const socket = io('https://app-dev.api.myeasytransfer.com')

        return new Promise<void>((resolve) => {
          // Connect to the socket.io server
          socket.on('connect', () => {
            console.log('Connected to server')
          })

          // Handle incoming events
          socket.on('change', (data) => {
            // Handle incoming data
            if (data?.userId === user?.user?._id) {
              if (statusComplete) {
                paiementInssurance(data)
                setNumStep(2)
                functionTracks(2)
              } else if (statusPending) {
                openModalStatus()
              } else if (statusNotFound) {
                navigate('/personalinformation')
              }

              socket.disconnect()

              resolve() // Resolve the promise when the condition is met
            }
          })

          // Optional: Handle disconnection
          socket.on('disconnect', () => {
            resolve() // Resolve when disconnected
          })
        })
      } catch (error) {
        console.log('Error starting background task:', error)
      }
    }
    StartSocketConnectionTask()
  }, [])

  return (
    <div>
      <div className="flex flex-col">
        <div className="text-[#A5A5A5] text-[15px] font-normal">
          Veuillez commencez votre inscription chez notre partenaire.
        </div>
        {loading ? (
          <div className="mt-5 text-center mx-auto">
            <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
          </div>
        ) : (
          <iframe src={url} className="w-full h-[600px] mb-8"></iframe>
        )}
      </div>
      {/* <div className="flex gap-[16px] mt-[25px] justify-center w-full h-[56px] sm:px-4 ">
        <button
          className={classNames(
            'text-[18px] text-[#556987] px-[28px] py-[16px] border border-[#556987] w-[180px] sm:w-full font-sans rounded-lg',
          )}
          onClick={() => {
            setNumStep(0)
          }}
        >
          Précédent
        </button>
        <button
          className={classNames(
            'text-[18px] bg-[#4743C9] hover:cursor-pointer text-[#FFF]  px-[28px] py-[16px] md:my-0 rounded-lg w-[375px] sm:w-full ',
          )}
          onClick={() => {
            setNumStep(2)
            functionTracks(2)
          }}
        >
          Suivant
        </button>
      </div> */}
      <ModalTestStatusUser
        isOpen={openIsModalStatus}
        closeModal={closeModalStatus}
        statusPending={statusPending}
        statusNotFound={statusNotFound}
      />
    </div>
  )
}
export default Inscription
