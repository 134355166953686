import React from 'react'
import {useNavigate } from 'react-router-dom'
import logoTransferPaiment from '../../assets/logoTransferPaimentGris.svg'
import logoTransferWallet from '../../assets/logoTransferWallet.svg'
import logoTransfer from '../../assets/logoTransfer.svg'
import logoRecharge from '../../assets/logoRecharge.svg'
import FlecheBlue from '../../assets/flechebleu.svg'
import assuranceImage from '../../assets/assuranceImageGris.svg'

const homeBlockLinks: React.FC = () => {
  const list: { image: string; linkTo: string; text: string }[] = [
    { image: logoTransfer, linkTo: '/transfert/0', text: "Transfert d'argent avec retrait en cash" },
    { image: logoRecharge, linkTo: '/recharge', text: 'Recharge mobile' },
    { image: logoTransferWallet, linkTo: '/transfert/1', text: "Transfert d'argent vers un compte bancaire" },
    { image: logoTransferPaiment, linkTo: '/paimentFacture', text: 'Paiement de factures locales' },
    { image: assuranceImage, linkTo: '/assurance', text: 'Souscription assurance locale' },
  ]

  const navigate = useNavigate()
  return (
    <div className="grid grid-cols-2 gap-[6%] lg:gap-4  items-center ">
      {list.map((item, index) => (

        <div
          key={index}
           onClick={() => { 
            if(!(item.linkTo === '/assurance' || item.linkTo==='/paimentFacture'))
            {navigate(item?.linkTo)}
          }}

          className={`flex h-[100px] justify-between items-center relative shadow-card px-3 sm:h-[150px] sm:px-0 sm:pl-2  rounded-xl  
          ${item.text === 'Souscription assurance locale' || item.text === 'Paiement de factures locales' ? 'bg-[#E5E7EA] cursor-not-allowed' : 'bg-[#FFFFFF] cursor-pointer'}
        `}
        >
          <div className={`flex items-center gap-3 sm:flex-col sm:items-start `}>
            <img className =' sm:w-[50px] sm:h-[50px]' width={55} height={55} src={item.image} alt={item.text}/>
            
            <div className={`font-bold sm:text-[16px]  lg:h-[55px] w-[200px] sm:w-full ${item.text === 'Souscription assurance locale' || item.text === 'Paiement de factures locales' ? 'text-[#9EA2AE] 3xl:w-[150px] sm:w-full ': 'text-black'}`}>
              {item.text}
            </div>
           
          </div>
          {item.text === 'Souscription assurance locale' || item.text === 'Paiement de factures locales' ? 
            <div className='bg-[#EE443F] rounded-lg text-[#FFFFFF] text-[12px] sm:text-[10px] font-semibold px-3 py-2 sm:py-1 sm:px-2 sm:absolute sm:top-8 sm:right-5 right-2 '>Bientôt</div>
            :
            <img className={`2xl:hidden `} width={30} height={30}  src={FlecheBlue}/>

            }
          
        </div>
       
      ))}
    </div>
  )
}

export default homeBlockLinks
