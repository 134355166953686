import * as React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

interface HistoriqueIconProps extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
  defaultStroke?: string;
  hoverStroke?: string;
}

const HistoriqueIcon: React.FC<HistoriqueIconProps> = ({
  isActive = false,
  defaultStroke = "#A5A5A5",
  hoverStroke = "#5754E5",
  ...props
}) => {
  const strokeColor = isActive ? hoverStroke : defaultStroke

  return (
    <svg 
    width="28" 
    height="28" 
    viewBox="0 0 25 25" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >

    <path d="M3 13.5C3 18.4706 7.0294 22.5 12 22.5C16.9706 22.5 21 18.4706 21 13.5C21 8.52944 16.9706 4.5 12 4.5C8.7927 4.5 5.9773 6.17765 4.3834 8.70327M3.2934 4.50076L3.4656 6.55452C3.5896 8.03384 3.6516 8.77349 4.1336 9.18914C4.6156 9.60479 5.3267 9.53185 6.749 9.38595L8.7934 9.17625" 
    stroke={strokeColor}
    className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 6L19 5"
    stroke={strokeColor}
    className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

     strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 10V14L14 16"
    stroke={strokeColor}
            className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

     strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 4V2.5"
    stroke={strokeColor}
            className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

     strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 2.5H14"
    stroke={strokeColor}
            className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

     strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}
HistoriqueIcon.propTypes = {
defaultStroke: PropTypes.string,
  hoverStroke: PropTypes.string,
}
export default HistoriqueIcon
