import React, { useContext, useEffect, useState } from 'react'
import iconSelectInput from '../../assets/iconSelectInput.svg'
import classNames from 'classnames'
import UserContext from 'contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import ModalTestStatusUser from 'components/modalTestStatusUser'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'

interface Props {
  banqueName: any
  setBanqueName: (value: string) => void
  iban: any
  setIban: (value: string) => void
  setNumStep: (value: number) => void
  setSelectedBnfList: (value: any) => void
  selectedBnfList: any
  cinBnf: any
  setCinBnf: (value: any) => void
  bankcode: any
  setBankcode: (value: any) => void
  swiftBic: any
  setSwiftBic: (value: any) => void
  functionTracks: any
}

const ListeCarteBancairePage: React.FC<Props> = ({
  banqueName,
  setBanqueName,
  iban,
  setIban,
  setNumStep,
  setSelectedBnfList,
  selectedBnfList,
  cinBnf,
  setCinBnf,
  bankcode,
  setBankcode,
  swiftBic,
  setSwiftBic,
  functionTracks,
}) => {
  const { user } = useContext(UserContext)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const navigate = useNavigate()

  //test status user
  const statusComplete = user?.user?.status === 'confirmed' && user?.user?.isDocumentSent
  const statusPending = user?.user?.status === 'pending' && user?.user?.isDocumentSent
  const statusNotFound = user?.user?.status === 'pending' && !user?.user?.isDocumentSent

  const [openList, setOpenList] = useState(false)
  const [confirmerNumeroCompte, setConfirmerNumeroCompte] = useState('')
  const [nameBanque, setNameBanque] = useState('')
  const [error, setError] = useState(true)
  const [isValid, setIsValid] = useState(true)
  const [isValidCin, setIsValidCin] = useState(true)
  const [openIsModalStatus, setOpenIsModalStatus] = useState(false)

  const [errorNumber, setErrorNumber] = useState(false)

  const openModalStatus = () => {
    setOpenIsModalStatus(true)
  }
  const closeModalStatus = () => {
    setOpenIsModalStatus(false)
  }

  const handleChangeCIN = (event: any) => {
    if (/^\d*$/.test(event.target.value)) {
      const isValidInput =
        /^[0-9]{8}$/.test(event.target.value) && !/\+/.test(event.target.value) && !/[a-zA-Z]/.test(event.target.value)
      setCinBnf(event.target.value)
      setTransferData({ ...TransferData, numeroCin: event.target.value })
      setIsValidCin(isValidInput)
    }
  }
  const test = (iban: any) => {
    const ibanPrefix = iban.substring(0, 2)
    const matchingBank = listCarteBancaire.find((bank) => bank.code === ibanPrefix)
    if (matchingBank) {
      setBanqueName(matchingBank.name)
      setBankcode(matchingBank.code)
      setSwiftBic(matchingBank.swift)
      setTransferData({
        ...TransferData,
        banqueName: matchingBank.name,
        numeroCard: iban,
        bankcode: matchingBank.code,
        swiftBic: matchingBank.swift,
      })
    } else {
      setBanqueName('')
      setBankcode('')
      setSwiftBic('')
      setTransferData({ ...TransferData, banqueName: '' })
    }
  }
  const handleChangeIBAN = (event: any) => {
    // const ibanWithoutPrefix = event.target.value.replace(/^TND59\s*/, '')
    // const startsWith59 = ibanWithoutPrefix.startsWith('59')
    const newValue = event.target.value

    // Allow only numbers
    if (/^\d*$/.test(newValue)) {
      const isValidInput = /^\d{20}$/.test(newValue)
      setIban(newValue)
      setTransferData({ ...TransferData, numeroCard: newValue })
      setError(isValidInput)
    }

    // setErrorNumber(startsWith59)
    test(newValue)
  }
  const handleInputChangeConfirme = (event: any) => {
    const newValue = event.target.value
    if (/^\d*$/.test(newValue)) {
      const isValidInput = /^\d{20}$/.test(newValue) && newValue === iban

      setConfirmerNumeroCompte(newValue)
      setTransferData({ ...TransferData, confirmerNumeroCompte: newValue })
      setIsValid(isValidInput)
    }
  }
  useEffect(() => {
    if (TransferData?.numeroCard !== iban) setTransferData({ ...TransferData, numeroCard: iban })
  }, [TransferData?.numeroCard])

  useEffect(() => {
    if (TransferData) {
      setIban(TransferData?.numeroCard || '')
      setCinBnf(TransferData?.numeroCin || '')
      setConfirmerNumeroCompte(TransferData?.confirmerNumeroCompte || '')
      setBanqueName(TransferData?.banqueName || '')
      setBankcode(TransferData?.bankcode || '')
      setSwiftBic(TransferData?.swiftBic || '')
    }
  }, [])

  const listCarteBancaire = [
    { index: 0, code: '08', name: 'BIAT', swift: 'BIATTNTT' },
    { index: 1, code: '03', name: 'BNA', swift: 'BNTETNTTSSE' },
    { index: 2, code: '10', name: 'STB', swift: 'STBKTNTTXXX' },
    { index: 3, code: '14', name: 'BH', swift: 'BHBKTNTT' },
    { index: 4, code: '04', name: 'Attijari', swift: 'BSTUTNTTXXX' },
    { index: 5, code: '07', name: 'Amen', swift: 'CFCTTNTTXXX' },
    { index: 6, code: '01', name: 'ATB', swift: 'ATBKTNTTXXX' },
    { index: 7, code: '12', name: 'UIB', swift: 'IUBKPAPAXXX' },
    { index: 8, code: '05', name: 'BT', swift: 'BTBKTNTTXXX' },
    { index: 9, code: '20', name: 'BTK', swift: 'BTKOTNTT' },
    { index: 10, code: '32', name: 'Baraka', swift: 'BEITTNTTXXX' },
    { index: 11, code: '11', name: 'UBCI', swift: 'UBCITNTTXXX' },
    { index: 12, code: '25', name: 'Zitouna', swift: 'BZITTNTTXXX' },
    { index: 13, code: '47', name: 'Wifak', swift: 'WKIBTNTTXXX' },
    { index: 14, code: '26', name: 'BTL', swift: 'ATLDTNTTXXX' },
    { index: 15, code: '23', name: 'QNB', swift: 'BTQITNTT' },
    { index: 16, code: '21', name: 'TSB', swift: 'TSIDTNTT' },
    { index: 17, code: '27', name: 'BTS', swift: 'TUSOTNT1XXX' },
    { index: 18, code: '24', name: 'BTE', swift: 'BTEXTNTTXXX' },
    { index: 19, code: '28', name: 'ABC', swift: 'ABCOTNTT ' },
  ]

  return (
    <div className="grid grid-cols-1 mt-5 sm:justify-center sm:ml-[25px] gap-5 sm:gap-0 sm:mt-0">
      <label className="text-[#1D3A70] font-bold text-[18px]">
        Nom du bénéficiaire <span className="text-red-500">*</span>
      </label>

      <div className="h-[75px] sm:mt-2">
        <div className="pt-4 px-5 w-full 2sm:w-[320px] h-[56px] border sm:h-[50px] font-bold text-[15px] rounded-xl text-[#1D3A70]">
          {selectedBnfList}
        </div>
      </div>

      <label className="text-[#1D3A70] font-bold text-[18px]">
        Numéro de CIN du bénéficiaire <span className="text-red-500">*</span>
      </label>
      <div className="h-[75px] sm:h-[95px] sm:w-[280px]">
        <input
          type="text"
          value={cinBnf}
          name="cinBnf"
          onChange={handleChangeCIN}
          placeholder="Numéro de CIN du bénéficiaire"
          autoComplete="off"
          className="p-2 w-full sm:mt-2 h-[56px] border sm:h-[50px] 2sm:w-[320px] font-bold text-[15px] rounded-xl text-[#1D3A70] placeholder:text-[#7f8082]"
          maxLength={8}
        />
        {!isValidCin && (
          <p className="text-red-500 text-[13px]">
            La carte d&apos;identité nationale doit contenir exactement 8 chiffres.
          </p>
        )}
      </div>

      <label className="text-[#1D3A70] font-bold text-[18px]">
        Numéro de compte bancaire <span className="text-red-500">*</span>
      </label>
      <div className="h-[75px] sm:h-[95px] sm:w-[280px]">
        <div className="rounded-xl h-[56px] border sm:h-[50px] 2sm:w-[320px] w-full flex">
          <span className="font-bold text-[15px]  text-[#1D3A70] flex items-center px-2">TN59</span>
          <input
            type="text"
            value={iban}
            name="iban"
            onChange={handleChangeIBAN}
            placeholder="Numéro de compte bancaire"
            className="p-2 w-full sm:mt-2  font-bold text-[15px]  text-[#1D3A70] placeholder:text-[#7f8082] focus:outline-none"
            maxLength={20}
          />
        </div>
        {!error && (
          <p className="text-red-500 text-[13px]">Le numéro de compte bancaire doit contenir exactement 20 chiffres.</p>
        )}

        {/* {errorNumber && <p className="text-red-500 text-[13px]">Le numéro 59 est deja existe.</p>} */}
      </div>

      <label className="text-[#1D3A70] font-bold text-[18px]">
        Confirmez le numéro de compte bancaire<span className="text-red-500">*</span>
      </label>
      <div className="h-[75px] sm:h-[95px] sm:w-[280px]">
        <div className="rounded-xl h-[56px] border sm:h-[50px] 2sm:w-[320px] w-full flex">
          <span className="font-bold text-[15px]  text-[#1D3A70] flex items-center px-2">TN59</span>
          <input
            type="text"
            value={confirmerNumeroCompte}
            name="confirmerNumeroCompte"
            onChange={handleInputChangeConfirme}
            maxLength={20}
            placeholder="Confirmez le numéro de compte bancaire"
            className=" w-full p-2  sm:mt-2  font-bold text-[15px]  text-[#1D3A70] placeholder:text-[#7f8082] focus:outline-none"
          />
        </div>
        {!isValid && <p className="text-red-500 text-[13px]">Les numéros de compte bancaire ne correspondent pas.</p>}
      </div>

      <div className="text-center text-[14px] sm:text-[12px] text-[#6B7280] w-[550px] sm:w-[300px] sm:pb-10">
        * Assurez-vous de vérifier soigneusement le numéro de compte bancaire avant d&lsquo;effectuer le transfert. En
        cas d&lsquo;erreur, il pourrait être difficile voire impossible de vous rembourser par la suite. Nous vous
        recommandons de prendre cette précaution pour garantir une transaction sans encombre.
      </div>

      <div className="flex sm:flex-col-reverse gap-[16px] justify-center w-full h-[56px] sm:h-[45px] sm:px-4 sm:mt-8">
        <button
          className={classNames(
            'text-[18px] text-[#556987] px-[28px] py-[16px] sm:py-3 border border-[#556987] w-[180px] sm:w-full font-sans rounded-lg',
          )}
          onClick={() => {
            setNumStep(1)
          }}
        >
          Précédent
        </button>

        <button
          className={classNames(
            banqueName === '' ||
              iban === '' ||
              confirmerNumeroCompte === '' ||
              confirmerNumeroCompte !== iban ||
              cinBnf.length > 8 ||
              cinBnf.length < 8 ||
              cinBnf.match(/^[0-9]+$/) === null
              ? 'bg-[#A5A5A5] hover:cursor-not-allowed'
              : 'bg-[#4743C9] hover:cursor-pointer',
            'text-[18px] text-[#FFF]  px-[28px] py-[16px] sm:py-3  md:my-0 rounded-lg w-[375px] sm:w-full ',
          )}
          onClick={() => {
            if (statusComplete) {
              setNumStep(3)
              functionTracks('3')
            } else if (statusPending) {
              openModalStatus()
            } else if (statusNotFound) {
              navigate('/personalinformation')
            }
          }}
          disabled={
            banqueName === null ||
            banqueName === '' ||
            iban === '' ||
            confirmerNumeroCompte === '' ||
            confirmerNumeroCompte !== iban ||
            cinBnf.length > 8 ||
            cinBnf.length < 8 ||
            cinBnf.match(/^[0-9]+$/) === null
          }
        >
          Suivant
        </button>
      </div>
      <ModalTestStatusUser
        isOpen={openIsModalStatus}
        closeModal={closeModalStatus}
        statusPending={statusPending}
        statusNotFound={statusNotFound}
      />
    </div>
  )
}

export default ListeCarteBancairePage
