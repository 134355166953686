import React, { useState, useRef } from 'react'
import ArrowDown from '../assets/arrowDown.svg'
import classNames from 'classnames'
import useClickOutside from '../hooks/useOnclickOutside'

interface Option {
  icon?: string
  value: string
  label: string
  background?: string
}

interface CustomSelectProps {
  options: Option[]
  value: string
  onChange: (selectedValue: string) => void
  placeholder?: string
  icon?: string
  width?: string
  height?: string
  textSize?: string
  background?: string
  showArrow?: boolean
  heightDropdown?: string
  marginRight?: string
  textColor?: string
  marginRightIcon?: string
  iconSelect?: string
  roundedIcon?: string
  phonePrefix?: any
  flag?: string
  disableMaroc? : boolean
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  icon,
  width,
  height,
  textSize,
  showArrow,
  background,
  heightDropdown = 'h-[200px]',
  marginRight,
  textColor,
  marginRightIcon,
  iconSelect,
  roundedIcon,
  phonePrefix,
  disableMaroc = false,
  flag,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [optionSelect, setOptionSelect]= useState<string | null>(null)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (selectedValue: string) => {
    if (!disableMaroc || disableMaroc && selectedValue === 'Tunisie'){
    onChange(selectedValue)
    setIsOpen(false)
    setOptionSelect(selectedValue)
  }
    
  }
  const divRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const toggleSetting = () => setIsOpen(false)
  useClickOutside(toggleSetting, dropDownRef, divRef)
  return (
    <div className={classNames(width, height, marginRight, 'relative')} ref={divRef}>
      <div
        className={`w-full h-full hover:cursor-pointer rounded-xl text-[#6B7280] shadow-sm flex items-center justify-between ${background ? background : 'bg-gray-100'}`}
        onClick={handleToggle}
      >
        <div className="flex items-center">
          {icon && (
            <img
              className={` h-[25px] ml-2 ${roundedIcon ? roundedIcon : 'rounded-[50%]'}`}
              src={icon}
              alt=""
              width={25}
              height={25}
            />
          )}

          {value ? (
            <span
              className={classNames(
                textSize,
                `whitespace-nowrap flex  flex-col ${textColor ? textColor : 'text-[#0C266C]'} ml-2`,
              )}
            >
              {flag && value ? flag : value}
            </span>
          ) : (
            <span className={classNames(textSize, ' whitespace-nowrap  ml-2')}>{placeholder}</span>
          )}
        </div>

        {showArrow ? (
          <img
            src={iconSelect ? iconSelect : ArrowDown}
            alt=""
            className={`${marginRightIcon ? marginRightIcon : ''}`}
            width={26}
            height={26}
          />
        ) : (
          <div className="w-[40px]" />
        )}
      </div>
      {isOpen && showArrow && (
        <div
          ref={dropDownRef}
          className={classNames(
            width,
            heightDropdown,
            ' absolute w-full bg-[#FFFFFF] shadow-selct  rounded mt-1  overflow-auto',
          )}
        >
          {options.map((option: any, index: number) => (
            <span
              key={index}
              className={`px-2 h-[50px] py-2 text-[14px] hover:bg-[#F5F5F5] sm:text-[12px] cursor-pointer flex gap-2 items-center ${
                option.label === 'Maroc' && 'text-[#424242] bg-white  cursor-default'
              } ${optionSelect === option.label && 'bg-[#F5F5F5]'} `}
               onClick={() => handleOptionClick(option.label) }
            >
              {option?.icon && <img src={option?.icon} alt="" width={25} height={25} />}
              {option.flag ? option.flag : option.label}
              {option.label === 'Maroc' && (
                <div className="bg-[#EC1C24] text-[#FFFFFF] px-1 rounded-sm absolute font-semibold right-28">
                  Bientôt
                </div>
              )}
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelect
