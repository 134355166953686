import SecureCardTransfert from 'components/transfertComponent/secureCardTransfert'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ValidateTransfer } from 'requests/transfer'

const Success = () => {
  const location = useLocation()
  const [responseSuccess, setResponseSuccess] = useState(null)
  const [transfertPage, setTransfertPage] = useState(1)
  const [rechargePage, setRechargePage] = useState(2)
  const piData = localStorage.getItem('pi')
  const fntrt = async (token: any, body: any) => {
    await ValidateTransfer(token, body)
      .then(async (response: any) => {
        if (response?.data?.success) {
          console.log('bien')
        } else {
          console.log('not bien')
        }
      })
      .catch((error: any) => {
        console.log('message error', error)
      })
    localStorage.removeItem('pi')
  }
  useEffect(() => {
    if (piData) {
      const parsedData = JSON.parse(piData)

      if (parsedData.paymentMode === 'pi') {
        console.log('parsedData', parsedData)
        const body = {
          transferId: parsedData.piID,
          promoCode: null,
        }
        fntrt(parsedData.token, body)
      }
    }
  }, [piData])
  useEffect(() => {
    if (location.state && location.state.responseSuccess) {
      setResponseSuccess(location.state.responseSuccess)
      setTransfertPage(location.state.responseSuccess)
      setRechargePage(location.state.responseSuccess)
    }
  }, [location])

  const [openModal, setOpenModal] = useState(false)
  const closeModalFunction = () => {
    setOpenModal(true)
  }
  return (
    <div>
      <SecureCardTransfert
        isOpen={true}
        closeModal={closeModalFunction}
        responseSuccess={true}
        transfertPage={1}
        rechargePage={2}
        invoice={3}
      />
    </div>
  )
}

export default Success
