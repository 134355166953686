import * as React from 'react'
import PropTypes from 'prop-types'

interface TransfertIconProps extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
  defaultStroke?: string;
  hoverStroke?: string;
}


const TransfertIcon: React.FC<TransfertIconProps>= ({
  isActive = false,
  defaultStroke = "#A5A5A5",
  hoverStroke = "#5754E5",
  ...props
}) => {
  const strokeColor = isActive ? hoverStroke : defaultStroke

  return (
    <svg width="28" height="28" 
      viewBox="0 0 25 25" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4987 22.9167V18.75M8.33203 20.8333V18.75M16.6654 20.8333V18.75" 
      stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
 
      strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20.5221 17.7085C21.4096 17.5413 22.0791 17.262 22.6172 16.7961C23.9596 15.6337 23.9596 13.7628 23.9596 10.021C23.9596 6.27923 23.9596 4.40834 22.6172 3.24592C21.2748 2.0835 19.1141 2.0835 14.793 2.0835H10.2096C5.88842 2.0835 3.72783 2.0835 2.38539 3.24592C1.04297 4.40834 1.04297 6.27923 1.04297 10.021C1.04297 13.7628 1.04297 15.6337 2.38539 16.7961C2.92349 17.262 3.59302 17.5413 4.48047 17.7085" 
      stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
 
      strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M19.2718 10.4165H19.2617" 
      stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
 
      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.74057 10.4165H5.73047" 
      stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
 
      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.625 13.0128C15.1267 13.9539 14.22 14.5833 13.1842 14.5833C11.6115 14.5833 10.3365 13.1324 10.3365 11.3426V9.49073C10.3365 7.70093 11.6115 6.25 13.1842 6.25C14.22 6.25 15.1267 6.87944 15.625 7.82056M9.375 10.4167H13.4616" 
      stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
 
      strokeWidth="1.5" strokeLinecap="round"/>
    </svg>

  )
}
TransfertIcon.propTypes = {
  defaultStroke: PropTypes.string,
    hoverStroke: PropTypes.string,
}
export default TransfertIcon
