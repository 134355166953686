import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import imageTransferArgent from '../../assets/imageTransferArgent.svg'
import imageRechargeMobile from '../../assets/imageRechargeMobile.svg'
import imageTransferWallet from '../../assets/imageTransferWallet.svg'
import imagePaimentFacture from '../../assets/imagePaimentFacture.svg'
import iconCloseModalRecharge from '../../assets/iconCloseModalRecharge.svg'

import { Link, useNavigate } from 'react-router-dom'
import iconUpdateProfileBeneficiary from '../../assets/iconUpdateProfileBeneficiary.svg'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import Avatar from 'components/avatar'

interface Props {
  nom: any
  num: any
  photo: number
  index?: number
  indexBeneficiary?: any
  isOpen: boolean
  person: any
  prefix : string
  closeModal: () => void
}

const ListFunction = [
  {
    icon: imageTransferArgent,
    transaction : "Transfert d'argent",
    color : 'bg-[#4743C9]',
    navigate: '/transfert/0'
  },
  {
    icon: imageTransferWallet,
    transaction : "Transfert vers un compte bancaire",
    color : 'bg-[#20C374]',
    navigate: '/transfert/1'
  },
  {
    icon: imageRechargeMobile,
    transaction : "Recharge mobile",
    color : 'bg-[#FFC000]',
    navigate: '/recharge'
  },
  {
    icon: imagePaimentFacture,
    transaction : "Paiement de factures locales",
    color : 'bg-[#FF3A44]',
    navigate: '/paimentFacture'
  }
]


const ModalHomeBeneficiary: React.FC<Props> = ({
  isOpen,
  closeModal,
  nom,
  num,
  photo,
  index,
  indexBeneficiary,
  person,
  prefix,
  
}) => {
  if (!isOpen) return null

  const navigate = useNavigate()
  const { beneficiaries } = useContext(BeneficiaryContext)

 

  return (
    <div className='fixed object-center justify-center items-center flex flex-col inset-0 z-50 bg-[#6c67ef2f] backdrop-blur-sm '>

      
        <div className='absolute'>
        <Avatar avatar={photo} border={true} styleCss='-mb-8 block overflow-visible bg-cover bg-center mx-auto w-[100px] h-[100px] ' />

        <div className= 'bg-center p-8 bg-image-bg-beneficiary bg-no-repeat '>
          <img
            onClick={closeModal}
            src={iconCloseModalRecharge}
            width={20}
            height={20}
            className="right-8 absolute hover:cursor-pointer"

          />

          <div className="flex flex-col justify-center items-center">
            <div className="mt-[3rem] text-[#1D3A70] text-[20px] font-semibold leading-5">{nom}</div>
            <div className="mt-3 text-[#666666] text-[14px] font-normal leading-3">{num}</div>

            <div className='flex flex-col gap-3 mt-8'>
                  { ListFunction.map((item, index)=>(
                    
                    <div
                    key={index}
                    className={classNames(
                     
                      'px-4 h-[60px] rounded-lg flex items-center gap-5 w-[300px] ',
                      {
                        [item.color]: !(prefix === '212' &&  (item.transaction === 'Paiement facture' || item.transaction === 'Transfert vers un compte bancaire')),
                        'hover:cursor-not-allowed bg-[#d0d0d1]': prefix === '212' && (item.transaction === 'Paiement facture' || item.transaction === 'Transfert vers un compte bancaire'),
                        'cursor-pointer ': !(prefix === '212' && (item.transaction === 'Paiement facture' || item.transaction === 'Transfert vers un compte bancaire')),
                      }
                    )}
                    onClick={() => {
                      if (!(prefix === '212' && (item.transaction === 'Paiement facture' || item.transaction === 'Transfert vers un compte bancaire'))) {
                        closeModal()
                        navigate(item.navigate)
                      }
                    }}
                  >
                  
                        <img  width={40} height={40} src={item.icon} alt={item.transaction}/>
                        <h3 className='text-[#FFFFFF] font-semibold'> {item.transaction}</h3>
                      
                    </div>

                  ))}
            </div>

            
            {/* <div
              className="flex items-center bg-[#4743C9] px-3 py-2 mt-[3rem] w-[300px] rounded-lg hover:cursor-pointer"
              onClick={() => {
                closeModal()
                if (indexBeneficiary === index) {
                  navigate('/transfert/0')
                }
              }}
            >
              <img src={imageTransferArgent} alt="image transfer" className="mr-[17px]" />
              <span className="text-white text-[18px] font-medium leading-3">Transfert d&apos;argent</span>
            </div> */}

            {/* {person?.personalInfo?.phone?.prefix === '+212' &&
            person?.personalInfo?.phone?.prefix === '212' ? (
              <div className="flex items-center bg-[#d0d0d1] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-not-allowed">
                <img src={imageTransferWallet} alt="image transfer" className="mr-[17px]" />
                <span className="text-black text-[16px] font-medium">Transfert vers un compte bancaire</span>
              </div>
            ) : (
              <div
                className="flex items-center bg-[#4743C9] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-pointer"
                onClick={() => {
                  closeModal()
                  if (indexBeneficiary === index) {
                    navigate('/transfert/1')
                  }
                }}
              >
                <img src={imageTransferWallet} alt="image transfer" className="mr-[17px]" />
                <span className="text-white text-[16px] font-medium">Transfert vers un compte bancaire</span>
              </div>
            )} */}

            {/* <div
              className="flex items-center bg-[#4743C9] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-pointer"
              onClick={() => {
                closeModal()
                if (indexBeneficiary === index) {
                  navigate('/recharge')
                }
              }}
            >
              <img src={imageRechargeMobile} alt="image transfer" className="mr-[17px]" />
              <span className="text-white text-[18px] font-medium leading-3">Recharge mobile</span>
            </div> */}

            {/* {beneficiaries?.personalInfo?.phone?.prefix === '+212' ||
            beneficiaries?.personalInfo?.phone?.prefix === '212' ? (
              <div className="flex items-center bg-[#d0d0d1] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-not-allowed">
                <img src={imageTransferWallet} alt="image transfer" className="mr-[17px]" />
                <span className="text-black text-[16px] font-medium">Paiement de factures</span>
              </div>
            ) : (
              <div
                className="flex items-center bg-[#4743C9] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-pointer"
                onClick={() => {
                  closeModal()
                  if (indexBeneficiary === index) {
                    navigate('/paimentFacture')
                  }
                }}
              >
                <img src={imagePaimentFacture} alt="image transfer" className="mr-[17px]" />
                <span className="text-white text-[18px] font-medium leading-3">Paiement de factures</span>
              </div>
            )} */}
            {/* <div className="flex items-center bg-[#d5d5d5] px-3 py-2 mt-[25px] w-[300px] rounded-lg hover:cursor-not-allowed">
              <img src={imagePaimentFacture} alt="image transfer" className="mr-[17px]" />
              <span className="text-black text-[18px] font-medium leading-3">Paiement de facture</span>
            </div> */}

            {/* <div
              className="flex items-center bg-[#F18C00] px-3 py-2 mt-[25px] w-[300px] rounded-lg hover:cursor-pointer"
              onClick={() => {
                closeModal()
                if (indexBeneficiary === index) {
                  navigate('/assurance')
                }
              }}
            >
              <img src={assuranceImage} alt="image transfer" className="mr-[17px]" />
              <span className="text-white text-[16px] font-medium">Souscription assurance locale</span>
            </div> */}
            {/* <div className="flex items-center bg-[#d5d5d5] px-3 py-2 mt-[25px] w-[300px] rounded-lg hover:cursor-not-allowed">
              <img src={assuranceImage} alt="image transfer" className="mr-[17px]" />
              <span className="text-black text-[18px] font-medium leading-3">Souscription assurance locale</span>
            </div> */}
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default ModalHomeBeneficiary
