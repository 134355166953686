import classNames from 'classnames'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ModalReinitialiserPasswordMobile from './ModalReinitialiserPasswordMobile'

interface Props {
  isOpen: boolean
  closeModal: () => void
  passwordSuccess: boolean
}

const ModalReinitialiserPassword: React.FC<Props> = ({ isOpen, closeModal, passwordSuccess }) => {
  if (!isOpen) return null
  const navigate = useNavigate()
  const [modalIosAndroid, setModalIosAndroid] = useState(false)

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1

  return (
    <div>
      <div
        className={classNames('justify-center items-center flex fixed inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm')}
      >
        <div className="relative my-6 mx-auto max-w-2xl">
          <div
            className={classNames(
              'border-0 rounded-xl pb-8 pt-5 px-[10rem] sm:px-8 md:px-8 sm:py-8 md:py-8 shadow-xl relative xl:py-4 flex flex-col w-full bg-white',
            )}
          >
            <div className="flex flex-col justify-center items-center mt-10">
              <div className="text-[#1D3A70] text-[25px] sm:text-[20px] md:text[20px] font-bold pb-1 text-center">
                {passwordSuccess ? 'Votre mot de passe a été modifié avec succès' : 'Vérifier votre mot de passe'}
              </div>
              <button
                onClick={() => {
                  if (passwordSuccess) {
                    if (!isIOS && !isAndroid) {
                      closeModal()
                      navigate('/login')
                    } else {
                      setModalIosAndroid(true)
                      
                    }
                  }
                  closeModal()
                }}
                className={`rounded-xl h-[50px] mt-8 text-white text-[15px] w-full font-medium
                  ${passwordSuccess ? 'bg-green-500' : 'bg-red-500'}
                  `}
              >
                Ok
              </button>
              {modalIosAndroid && (
                <ModalReinitialiserPasswordMobile isAndroid={isAndroid} isIos={isIOS} closeModal={closeModal} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalReinitialiserPassword
