import { platform } from 'os'
import axios from './axios'

// SIGNUP AUTH
export const Signup = (signupObject: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    
    if (signupObject) {
      const body = {
        platform: 'web',
        firstName: signupObject.nom,
        lastName: signupObject.prenom,
        gender: signupObject.valueGender,
        phoneNumber: signupObject.telephone,
        phonePrefix: signupObject.prefix,
        country: signupObject.contryCode,
        countryCode: signupObject.valuePaysSelect,
        email: signupObject.email,
        password: signupObject.password,
        referralCode: '',
        language: 'fra'
      }

      axios
        .post('/users/signup', body)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    } else {
      reject(new Error('signupObject est manquant ou invalide'))
    }
  })
}

// API FOR KYC 

export const UpdateForKyc = (id: any, token: any, updateForKycObject: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!updateForKycObject) {
      reject(new Error('updateForKycObject est manquant ou invalide'))
      return
    }
    const body = {
      userId: id,
      birthDate: updateForKycObject.dateNaissance,
      street: updateForKycObject.adresseResidance,
      zipCode: updateForKycObject.codePostal,
      city: updateForKycObject.ville, 
      countryOfBirthCode: updateForKycObject.countryOfBirthCode,
      countryOfBirthName: updateForKycObject.selectedOptionPays,
      platform : 'web'
    }
    axios.post(`/users/updateForKyc`,  body, {
      headers: {
        "authorization": token,
        "Content-Type": 'application/json',
      },
    }).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}
  

export const activateAccount = (token: any) => {
  return new Promise((resolve, reject) => {
    const body = {
      token: token,
      platform: 'web',
    }
    axios.post('/auth/activation', body)
      .then((response) => {
        resolve(response.data)

      })
      .catch((err) => {
        reject(err)
      })
  })
}
 


// API AUTH
export const Login = ({ email, password }: any) =>
  new Promise((resolve, reject) => {
    if (email && password) {
      axios
        .post('/auth/user', { email, password, platform: 'web' })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    }
  })

// API CALCULATE

export const getCurrencies = () =>
  axios
    .get(`/currencies`)
    .then((response) => {
      if (response?.data?.currencies) {
        return response?.data?.currencies
      }
    })
    .catch((error) => {
      console.log(error)
    })

export const getFxRate = (
  departureCurrencyId: string,
  destinationCurrencyId: string,
) =>
  axios
    .get(
      `/fxrates/fxrate?departureCurrencyId=${departureCurrencyId}&destinationCurrencyId=${destinationCurrencyId}`,
    )
    .then((response) => {
      if (response?.data?.fxRate) {
        return response?.data?.fxRate
      }
    })
    .catch((error) => {
      console.log(error)
    })

export const commission = (currencyId: string, amountToSend: string) =>
  axios
    .get(
      `/commissions/commission?currencyId=${currencyId}&amountToSend=${amountToSend}`,
    )
    .then((response) => {
      if (response?.data?.commission) {
        return response?.data?.commission
      }
    })
    .catch((error) => {
      console.log(error)
    })


// Beneficiares

export const AddBeneficiary = (id:any, token: any, data:any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/beneficiaries/${id}`,  data, {headers:{
          "authorization": token,
          "Content-Type": 'application/json',
        }}
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}