import React, { useContext, useState } from 'react'
import ModalHomeBeneficiary from '../containers/homeContainers/modalHomeBeneficiary'
import Avatar from './avatar'

interface props {
  nom? : string
  num? : string
  photo: any
  genre ?: string
  index: number
  person: object
  setIndexBeneficiairy: any
  indexBeneficiary: any
  item:any
  afficheName?: boolean
  prefix : string
  lastName? : string
  isClickable?: boolean

}

const BenificePerson: React.FC<props> = ({
  nom,
  num,
  photo,
  genre,
  setIndexBeneficiairy,
  index,
  person,
  indexBeneficiary,
  item,
  afficheName,
  prefix,
  lastName,
  isClickable=true
}) => {
  const [isOpenModal, setIsopenModal] = useState(false)

  const openModalBeneficiaryPerson = (person: any) => {
    setIsopenModal(true)
  }
  const closeModalBeneficiaryPerson = () => {
    setIsopenModal(false)
  }

  return (
    <>
      <div
        className={`${isClickable? ' cursor-pointer': ''} flex items-center gap-[24px] `}
        onClick={() => {
          
          setIndexBeneficiairy(index)
          openModalBeneficiaryPerson(person)
        }}
      >
        <Avatar 
        genre={genre}
        avatar={photo} 
        name={nom}
        num={num} 
        afficheName={afficheName}
        lastName={lastName}
        
        />
       
      </div>
      {isClickable && 
      <ModalHomeBeneficiary
        nom={`${item?.personalInfo?.gender === 'male' ? 'Mr' : 'Mme'} ${nom}`}
        num={num}
        photo={item.avatar ? item.avatar : item.personalInfo.gender}

        // photo={photo}
        index={index}
        indexBeneficiary={indexBeneficiary}
        isOpen={isOpenModal}
        closeModal={closeModalBeneficiaryPerson}
        person={person}
        prefix={prefix}
      />}
    </>
  )
}
export default BenificePerson
