import React, { useState, useContext, useEffect } from 'react'
import UserContext from 'contexts/UserContext'
import Notifications from 'components/Notifications'
import Montant from './transfertContainer/montant'
import Synthese from '../components/Synthese'
import Benefiaire from './transfertContainer/beneficiare'
import PointCollecte from './transfertContainer/pointColect'
import Paiement from './transfertContainer/payment'
import { PartnersList } from 'requests/partner'
import { Partner, ListPartner, AddressPartnerInput, PartenaireList } from 'types/Transfert'
import Step from '../components/Step'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import StepTransfert from 'components/stepTransfert'
import { getListByCountry, tracks } from 'requests/transfer'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'

function containsObject(name: string, list: any) {
  if (list.length > 0) {
    var i
    for (i = 0; i < list.length; i++) {
      if (list[i].name === name) {
        return true
      }
    }
  }
  return false
}

export default function DashboardTransfert() {
  const codeParams = useParams()?.code

  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState<any>('0')

  const [partnersData, setPartnersData] = useState<Partner[]>([])

  const { user } = useContext(UserContext)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const [openalert, setOpenAlert] = useState<boolean>(false)
  const [amountD, setAmountD] = useState(100)

  const [valuePays, setValuePays] = useState(
    TransferData?.valuePays === 'TND'
      ? { value: 'TND' }
      : TransferData?.valuePays === 'MAD'
        ? { value: 'MAD' }
        : user?.user?.destinationDefaultCountry === 'MAC'
          ? { value: 'MAD' }
          : { value: 'TND' },
  )

  const [selectedValue, setSelectedValue] = useState<string>(
    code === '1' ? 'TUN' : user?.user?.address?.country?.code ? user?.user?.address?.country?.code : '',
  )

  const [departureCurrencyId, setDepartureCurrencyId] = useState<string>('')
  const [destinationCurrencyId, setDestinationCurrencyId] = useState<string>('')

  const [euroValue, setEuroValue] = useState(100)
  const [dinarValue, setDinarValue] = useState(0)

  const [currencyToTND, setCurrencyToTND] = useState(0)
  const [modePay, setModePay] = useState<string>('cb')
  const [com, setCom] = useState<number>(0)
  const [taux, setTaux] = useState<number>(1)

  //propsBeneficaire
  const [benificiareId, setBenificiareId] = useState<string>('')
  const [motif, setMotif] = useState<string>('')

  //propspoint de collecte
  const [colectPointId, setColectPointId] = useState<string>('')

  const nomstep = ['Montant', 'Bénéficiaire', 'Destination', 'Paiement']

  const [numStep, setNumStep] = useState(0)

  //propsPaiment
  const [idBeneficiary, setIdBeneficiary] = useState('')
  const [reseaux, setReseaux] = useState('')
  const [networkCheckedMaroc, setNetworkCheckedMaroc] = useState(0)
  const [fxRateFirstTransfer, setFxRateFirstTransfer] = useState()

  //carte bancaire value
  const [choisirCarte, setChoisirCarte] = useState<any>(0)
  const [banqueName, setBanqueName] = useState('')
  const [iban, setIban] = useState('')

  const [selectedBnfList, setSelectedBnfList] = useState('')
  const [choisirModePayment, setChoisirModePayment] = useState<any>(0)
  const [paymentMode, setPaymentMode] = useState('')

  const [bnfidentifiant, setBnfidentifiant] = useState('')
  const [bankcode, setBankcode] = useState('')
  const [swiftBic, setSwiftBic] = useState('')

  const [promoCode, setPromoCode] = useState('')

  const [trackId, setTrackId] = useState('')
  useEffect(() => {
    setCode(codeParams)
    setNumStep(0)
    if (TransferData?.valuePays && TransferData?.valuePays === 'MAD' && codeParams === '1')
      setTransferData({
        ...TransferData,
        BeneficiaryName: '',
        idBeneficiary: '',
        Motif: '',
      })
  }, [codeParams])
  useEffect(() => {
    async function fetchData() {
      const token = user?.token?.accessToken
      const country = valuePays.value === 'TND' ? 'Tunisie' : 'Maroc'
      const response: ListPartner = (await PartnersList(token, country)) as ListPartner
      if (response.success === true) {
        setPartnersData(response.partners)
        const arr: AddressPartnerInput[] = []
        response.partners.map((val, index: number) => {
          if (!containsObject(val.address.governorate, arr)) {
            arr.push({ name: val.address.governorate, index, ...val.address })
          }
        })
        setLoading(true)
      }
    }
    fetchData()
  }, [valuePays?.value])

  const functionTracks = async (stepNumber: string) => {
    const id = user?.user?._id
    let dataTracks = {
      label: code === '0' ? 'cash_transfer' : 'bank_transfer',
      step: stepNumber,
      userId: id,
    }
    if (stepNumber !== '0') {
      const newDataTracks = {
        ...dataTracks,
        trackId: trackId,
      }
      dataTracks = newDataTracks
    }
    try {
      const response = await tracks(dataTracks)
      if (response?.success === true) {
        setTrackId(response?.trackId)
      } else {
        console.error('response failed', response)
      }
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    functionTracks('0')
  }, [])

  return (
    // xl:items-center gap-[5rem] sm:gap-0 3xl:gap-2 2xl:gap-0 6xl:gap-[10px] pb-8 max-w-max
  <div className="flex justify-between xl:items-center  xl:flex-col  ">
    {/* flex justify-between xl:items-center  xl:flex-col sm:mx-auto */}
    <div className="flex flex-col items-center w-full lg:mt-[50px] ">
      <div className={`h-[200px] sm:h-[150px] 2sm:h-[150px]`}>

        <div className="sm:text-[25px] text-[#1D3A70] text-center sm:px-2 text-[35px] 2xl:text-[30px] font-semibold ">
            {codeParams === '1'
              ? "Transfert d'argent vers un compte bancaire"
              : "Transfert d'argent avec retrait en cash"}
          </div>

          <div className="flex justify-center mt-[30px] sm:mt-4 ">
            {nomstep.map((item, index) => (
              <StepTransfert
                key={index}
                nomStep={item}
                numStep={index + 1}
                isActive={index <= numStep}
                lastStep={index < 3 ? true : false}
                isComplete={numStep > index}
              />
            ))}
          </div>
        </div>
       
        {numStep === 0 && (
          <Montant
            amountD={amountD}
            setAmountD={(value) => setAmountD(value)}
            euroValue={euroValue}
            setEuroValue={(value) => setEuroValue(value)}
            dinarValue={dinarValue}
            setDinarValue={(value) => setDinarValue(value)}
            selectedValue={selectedValue}
            setSelectedValue={(value) => setSelectedValue(value)}
            currencyToTND={currencyToTND}
            setCurrencyToTND={(value) => setCurrencyToTND(value)}
            com={com}
            setCom={(value) => setCom(value)}
            modePay={modePay}
            setModePay={(value) => setModePay(value)}
            taux={taux}
            setTaux={(value) => setTaux(value)}
            setDepartureCurrencyId={(value) => setDepartureCurrencyId(value)}
            setDestinationCurrencyId={(value) => setDestinationCurrencyId(value)}
            setNumStep={setNumStep}
            setChoisirCarte={(value) => setChoisirCarte(value)}
            setBanqueName={(value) => setBanqueName(value)}
            setIban={(value) => setIban(value)}
            setColectPointId={(value) => setColectPointId(value)}
            setReseaux={(value) => setReseaux(value)}
            choisirModePayment={choisirModePayment}
            setChoisirModePayment={(value) => setChoisirModePayment(value)}
            paymentMode={paymentMode}
            setPaymentMode={(value) => setPaymentMode(value)}
            promoCode={promoCode}
            setPromoCode={(value) => setPromoCode(value)}
            code={code}
            valuePays={valuePays}
            setValuePays={(value) => setValuePays(value)}
            setFxRateFirstTransfer={(value) => setFxRateFirstTransfer(value)}
            fxRateFirstTransfer={fxRateFirstTransfer}
            functionTracks={functionTracks}
          />
        )}

        {numStep === 1 && (
          <Benefiaire
            benificiare={benificiareId}
            setBenificiareId={(value) => setBenificiareId(value)}
            motif={motif}
            setMotif={(value) => setMotif(value)}
            setNumStep={setNumStep}
            setIdBeneficiary={(value) => setIdBeneficiary(value)}
            apiData={partnersData}
            idBeneficiary={idBeneficiary}
            setColectPointId={(value) => setColectPointId(value)}
            colectPointId={colectPointId}
            setSelectedBnfList={(value) => setSelectedBnfList(value)}
            selectedBnfList={selectedBnfList}
            valuePays={valuePays}
            functionTracks={functionTracks}
            code={code}
            selectedValue={selectedValue}
          />
        )}

        {numStep === 2 && (
          <PointCollecte
            setColectPointId={(value) => setColectPointId(value)}
            setNumStep={setNumStep}
            idBeneficiary={idBeneficiary}
            apiData={partnersData}
            colectPointId={colectPointId}
            setChoisirCarte={(value) => setChoisirCarte(value)}
            choisirCarte={choisirCarte}
            banqueName={banqueName}
            setBanqueName={(value) => setBanqueName(value)}
            iban={iban}
            setIban={(value) => setIban(value)}
            setReseaux={(value) => setReseaux(value)}
            setSelectedBnfList={(value) => setSelectedBnfList(value)}
            selectedBnfList={selectedBnfList}
            bnfidentifiant={bnfidentifiant}
            setBnfidentifiant={(value) => setBnfidentifiant(value)}
            bankcode={bankcode}
            setBankcode={(value) => setBankcode(value)}
            swiftBic={swiftBic}
            setSwiftBic={(value) => setSwiftBic(value)}
            code={code}
            valuePays={valuePays}
            networkCheckedMaroc={networkCheckedMaroc}
            setNetworkCheckedMaroc={(value) => setNetworkCheckedMaroc(value)}
            euroValue={euroValue}
            functionTracks={functionTracks}
            reseaux={reseaux}
          />
        )}

        {numStep === 3 && (
          <Paiement
            departureCurrencyId={departureCurrencyId}
            destinationCurrencyId={destinationCurrencyId}
            amountD={euroValue}
            currencyToTND={currencyToTND}
            modePay={modePay}
            com={com}
            totalSend={euroValue + taux}
            benificiareId={benificiareId}
            idBeneficiary={idBeneficiary}
            motif={motif}
            colectPointId={colectPointId}
            senderId={user?.user?._id}
            token={user?.token?.accessToken}
            taux={taux}
            setOpenAlert={() => setOpenAlert(!openalert)}
            banqueName={banqueName}
            iban={iban}
            choisirCarte={choisirCarte}
            reseaux={reseaux}
            paymentMode={paymentMode}
            bankcode={bankcode}
            swiftBic={swiftBic}
            bnfidentifiant={bnfidentifiant}
            promoCode={promoCode}
            valuePays={valuePays}
            networkCheckedMaroc={networkCheckedMaroc}
            fxRateFirstTransfer={fxRateFirstTransfer}
            functionTracks={functionTracks}
          />
        )}
      </div>
      <div className="mt-[2rem] pr-5 md:mt-0 sm:ml-0 sm:hidden">
        <Synthese
          text="Total à payer"
          LabelMantantToSend="Montant à envoyer"
          mantantToSend={euroValue}
          labelTaux="Taux de change"
          taux={com}
          labelCom="Commission"
          com={taux}
          LabelMantantToRecu="Montant à recevoir"
          mantantToRecu={euroValue * com}
          valuePays={valuePays}
        />
      </div>
      {openalert && <Notifications type="succes" />}
    </div>
  )
}
