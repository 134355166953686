import React from 'react'
import avatar1 from '../assets/avatar1.svg'
import avatar2 from '../assets/avatar2.svg'
import avatar3 from '../assets/avatar3.svg'
import avatar4 from '../assets/avatar4.svg'
import avatar5 from '../assets/avatar5.svg'
import avatar6 from '../assets/avatar6.svg'
import avatar7 from '../assets/avatar7.svg'
import avatar8 from '../assets/avatar8.svg'
import avatar9 from '../assets/avatar9.svg'
import avatar10 from '../assets/avatar10.svg'
import avatar11 from '../assets/avatar11.svg'
import avatar12 from '../assets/avatar12.svg'
import avatar13 from '../assets/avatar13.svg'
import avatar14 from '../assets/avatar14.svg'
import avatar15 from '../assets/avatar15.svg'
import avatar16 from '../assets/avatar16.svg'
import avatar17 from '../assets/avatar17.svg'
import avatar18 from '../assets/avatar18.svg'
import avatar19 from '../assets/avatar19.svg'
import avatar20 from '../assets/avatar20.svg'
import avatar21 from '../assets/avatar21.svg'
import avatar22 from '../assets/avatar22.svg'
import avatar23 from '../assets/avatar23.svg'
import avatar24 from '../assets/avatar24.svg'
import newDefaultImageFemme from '../assets/newDefaultImageFemme.svg'
import newDefaultImageHomme from '../assets/newDefaultImageHomme.svg'
import classNames from 'classnames'


const dataAvatar = [
    { index: 1, image: avatar1 },
    { index: 2, image: avatar2 },
    { index: 3, image: avatar3 },
    { index: 4, image: avatar4 },
    { index: 5, image: avatar5 },
    { index: 6, image: avatar6 },
    { index: 7, image: avatar7 },
    { index: 8, image: avatar8 },
    { index: 9, image: avatar9 },
    { index: 10, image: avatar10 },
    { index: 11, image: avatar11 },
    { index: 12, image: avatar12 },
    { index: 12, image: avatar13 },
    { index: 14, image: avatar14 },
    { index: 15, image: avatar15 },
    { index: 16, image: avatar16 },
    { index: 17, image: avatar17 },
    { index: 18, image: avatar18 },
    { index: 19, image: avatar19 },
    { index: 20, image: avatar20 },
    { index: 21, image: avatar21 },
    { index: 22, image: avatar22 },
    { index: 23, image: avatar23 },
    { index: 24, image: avatar24 },
    { index: 25, image: newDefaultImageHomme },
    { index: 26, image: newDefaultImageFemme }
  ]
interface Props{
    genre ? : string
    avatar : any
    border? : boolean
    styleCss?: string
    name? : string
    num? : string
    afficheName ? : boolean
    lastName? : string
}

const Avatar : React.FC<Props> = ({avatar, border =false, styleCss, name, num, afficheName=false, genre,lastName}) => {

    const matchedAvatar = dataAvatar.find((v) => v.index === avatar)

  return (
    
      <div className={` ${afficheName ? 'flex w-full gap-[12%] items-center' : ' flex-col items-center justify-center'}`}>
        <img className= {classNames( styleCss,{'border-[#FFFFFF] border-8 rounded-full ' : border})} width={52} height={52}
         src={ matchedAvatar ? matchedAvatar?.image : genre === 'male' ?  newDefaultImageHomme : newDefaultImageFemme }
            alt='avatar' >
          </img>
        { afficheName ? 
          <div className='text-[18px] lg:text-[15px] '>
              <div className=" text-[#0A0A0A] "> {name}</div>
              <div className="text-[#B4B4B4] mt-[3px]">{num}</div>
          </div>
          :
          <div className=" text-[#0A0A0A] text-sm text-center ">{lastName?.slice(0,6)}</div>
          
        }
      </div>

    
  )
}

export default Avatar
