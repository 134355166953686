import React, { useState } from 'react'
import statushistory from '../../assets/statushistory.svg'
import iconTroisPoints from '../../assets/iconTroisPoints.svg'
import iconPrint from '../../assets/iconPrint.svg'
import ModalPrintHistory from './modalPrintHistory'
import imageDefault from '../../assets/imageDefault.jpeg'
import MoadlPrintRecharge from './modalPrintRecharge'

interface Props {
  rechargeData: any
}

const HistoryTableRecharge: React.FC<Props> = ({ rechargeData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [status, setStatus] = useState<string>('Complete')
  const [statusCanceled, setStatusCanceled] = useState<string>('canceled')
  const [statusInProgress, setStatusInProgress] = useState<string>('in progress')

  function formatDate(originalDate: any) {
    const dateObj = new Date(originalDate)
    const jour = dateObj.getDate()
    const mois = dateObj.getMonth() + 1
    const annee = dateObj.getFullYear()
    const jourFormatte = jour < 10 ? `0${jour}` : jour
    const moisFormatte = mois < 10 ? `0${mois}` : mois
    return `${jourFormatte}/${moisFormatte}/${annee}`
  }

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIndexrecharge(-1)
    setIsModalOpen(false)
  }
  const [clickedRows, setClickedRows] = useState<boolean[]>([])
  const [indexrecharge, setIndexrecharge] = useState(-1)

  const handleInputClick = (index: number) => {
    const newClickedRows = [...clickedRows]
    newClickedRows[index] = !newClickedRows[index]
    setClickedRows(newClickedRows)
  }
  return (
    <div className="mx-auto mt-[4rem] mr-[5rem] sm:mr-0 lg:mr-1">
      <div className="table-container">
        <table className="table-auto w-full flex1 text-left">
          <thead>
            <tr>
              {/* <th className="border-b px-4 py-2 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393]">Bénéficiaire</div>
              </th> */}
              <th className="border-b pr-4 py-2 sm:border-none sm:hidden">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393]">Numéro de téléphone</div>
              </th>
              <th className="border-b px-4 py-2 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393]">Montant envoyé</div>
              </th>
              <th className="border-b px-4 py-2 sm:border-none sm:hidden">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393]">Montant payé</div>
              </th>
              <th className="border-b px-4 py-2 sm:border-none sm:hidden">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393]">Date</div>
              </th>
              <th className="border-b px-4 py-2 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393] ">Statut</div>
              </th>
              {/* <th className="border-b px-4 py-2 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393] sm:hidden">Reçu</div>
              </th> */}
            </tr>
          </thead>
          {rechargeData &&
            rechargeData.map((listRecharge: any, index: number) => (
              <tbody key={index}>
                <tr className="hover:bg-gray-100">
                  <td className={`border-b pr-4 lg:pl-4 lg:px-2 py-2`} onClick={() => handleInputClick(index)}>
                    <div className="text-[#22242C] text-[14px] sm:text-[11px] lg:text-[13px] font-semibold">
                      {listRecharge?.DistributorRef === 'INMA' ||
                      listRecharge?.DistributorRef === 'ORMA' ||
                      listRecharge?.DistributorRef === 'MOMA'
                        ? '+212 '
                        : '+216 '}
                      {listRecharge?.AccountNumber}
                    </div>
                  </td>

                  <td className="border-b px-4 py-2 sm:hidden">
                    <div className="text-[#22242C] text-[14px] lg:text-[13px] font-semibold">
                      {listRecharge?.ReceiveValue} {listRecharge?.ReceiveCurrencyIso}
                    </div>
                  </td>
                  <td className="border-b px-4 py-2">
                    <div className="text-[#22242C] text-[14px] lg:text-[13px] font-semibold">
                      {listRecharge?.SendValue ? `${listRecharge?.SendValue} EUR` : ''}
                    </div>
                  </td>
                  <td className="border-b px-4 py-2 sm:hidden">
                    <div className="text-[#22242C] text-[14px] lg:text-[13px] font-semibold">
                      {formatDate(listRecharge?.createdAt)}
                    </div>
                  </td>

                  <td className="border-b">
                    {status === listRecharge?.status ? (
                      <div className="bg-[#EBFFF3] flex items-center text-[14px] justify-center w-auto p-2 sm:p-[5px] rounded-lg">
                        <span className="text-[#20C374] ml-1">Terminé</span>
                      </div>
                    ) : statusInProgress === listRecharge?.status ? (
                      <div className="bg-[#FFDECC] flex items-center justify-center w-auto p-2 h-[35px] 2md:h-[22px] text-[14px] rounded-lg">
                        <span className="text-[#FF5C00]">En cours</span>
                      </div>
                    ) : statusCanceled === listRecharge?.status ? (
                      <div className="bg-[#FED7D8] flex items-center justify-center w-auto p-2 h-[35px] 2md:h-[22px] text-[14px] rounded-lg">
                        <span className="text-[#EC1C24]">Annulé</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                  {/* <td className="border-b px-4 py-2">
                    <div className="flex ml-4 sm:ml-0">
                      <img
                        src={iconPrint}
                        className="w-5 h-5 mr-5 cursor-pointer sm:hidden"
                        onClick={() => {
                          //openModal()
                          setIndexrecharge(index)
                        }}
                      />
                    </div>
                  </td> */}
                </tr>
                {/* <MoadlPrintRecharge isOpen={index === indexrecharge} closeModal={closeModal} list={listRecharge} /> */}
              </tbody>
            ))}
        </table>
      </div>
    </div>
  )
}

export default HistoryTableRecharge
