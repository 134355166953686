import * as React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function HomeIcon({
  isActive = false,
  defaultStroke = "#A5A5A5",
  hoverStroke = "#5754E5",
  ...props
}: React.SVGProps<SVGSVGElement> & {
  isActive?: boolean
  defaultStroke?: string;
  hoverStroke?: string;
}) {

  const strokeColor = isActive ? hoverStroke : defaultStroke

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="group"
    >
    <path d="M2.08203 6.25016C2.08203 4.28598 2.08203 3.30388 2.69223 2.69369C3.30242 2.0835 4.28451 2.0835 6.2487 2.0835C8.21289 2.0835 9.19498 2.0835 9.80517 2.69369C10.4154 3.30388 10.4154 4.28598 10.4154 6.25016V8.3335C10.4154 10.2977 10.4154 11.2797 9.80517 11.89C9.19498 12.5002 8.21289 12.5002 6.2487 12.5002C4.28451 12.5002 3.30242 12.5002 2.69223 11.89C2.08203 11.2797 2.08203 10.2977 2.08203 8.3335V6.25016Z" 
     stroke={strokeColor}
        className={classNames(
          "group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
        )}
        strokeWidth="1.5"/>

    <path d="M2.08203 19.7915C2.08203 18.8208 2.08203 18.3355 2.24061 17.9525C2.45206 17.4421 2.85764 17.0365 3.3681 16.825C3.75097 16.6665 4.23632 16.6665 5.20703 16.6665H7.29036C8.26107 16.6665 8.74643 16.6665 9.12929 16.825C9.63976 17.0365 10.0453 17.4421 10.2568 17.9525C10.4154 18.3355 10.4154 18.8208 10.4154 19.7915C10.4154 20.7622 10.4154 21.2475 10.2568 21.6305C10.0453 22.1409 9.63976 22.5465 9.12929 22.758C8.74643 22.9165 8.26107 22.9165 7.29036 22.9165H5.20703C4.23632 22.9165 3.75097 22.9165 3.3681 22.758C2.85764 22.5465 2.45206 22.1409 2.24061 21.6305C2.08203 21.2475 2.08203 20.7622 2.08203 19.7915Z" 
     stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
        strokeWidth="1.5"/>

    <path d="M14.582 16.6667C14.582 14.7025 14.582 13.7204 15.1922 13.1102C15.8024 12.5 16.7845 12.5 18.7487 12.5C20.7129 12.5 21.6949 12.5 22.3052 13.1102C22.9154 13.7204 22.9154 14.7025 22.9154 16.6667V18.75C22.9154 20.7142 22.9154 21.6962 22.3052 22.3065C21.6949 22.9167 20.7129 22.9167 18.7487 22.9167C16.7845 22.9167 15.8024 22.9167 15.1922 22.3065C14.582 21.6962 14.582 20.7142 14.582 18.75V16.6667Z" 
     stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
        strokeWidth="1.5"/>

    <path d="M14.582 5.2085C14.582 4.23779 14.582 3.75243 14.7406 3.36957C14.952 2.8591 15.3577 2.45353 15.8681 2.24208C16.251 2.0835 16.7363 2.0835 17.707 2.0835H19.7904C20.7611 2.0835 21.2464 2.0835 21.6293 2.24208C22.1397 2.45353 22.5454 2.8591 22.7568 3.36957C22.9154 3.75243 22.9154 4.23779 22.9154 5.2085C22.9154 6.1792 22.9154 6.66456 22.7568 7.04742C22.5454 7.55789 22.1397 7.96346 21.6293 8.17491C21.2464 8.3335 20.7611 8.3335 19.7904 8.3335H17.707C16.7363 8.3335 16.251 8.3335 15.8681 8.17491C15.3577 7.96346 14.952 7.55789 14.7406 7.04742C14.582 6.66456 14.582 6.1792 14.582 5.2085Z" 
     stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
        strokeWidth="1.5"/>
</svg>

 
  )
}
HomeIcon.propTypes = {
  defaultStroke: PropTypes.string,
  hoverStroke: PropTypes.string,
}
export default HomeIcon
