import UserContext from 'contexts/UserContext'
import React, { useContext, useEffect, useState } from 'react'
import iconAddBnf from '../../assets/iconAddBnf.svg'

import { Spinner } from 'utils/loading'
import { getListBeneficary } from 'requests/benificary'
import ModalBeneficiary from 'containers/beneficiaireContainer/modalBeneficiary'
import ModalAddBeneficiary from 'containers/beneficiaireContainer/modalAddBeneficiary'

import Avatar from 'components/avatar'

const ContainerHome = () => {
  const [person, setPerson] = useState<any>([])
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null)
  const [isAdded, setIsAdded] = useState(false)
  // const [newImage, setNewImage] = useState('')
  // const [newImageSelected, setNewImageSelected] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null)

  // const dataAvatar = [
  //   { index: 1, image: avatar1 },
  //   { index: 2, image: avatar2 },
  //   { index: 3, image: avatar3 },
  //   { index: 4, image: avatar4 },
  //   { index: 5, image: avatar5 },
  //   { index: 6, image: avatar6 },
  //   { index: 7, image: avatar7 },
  //   { index: 8, image: avatar8 },
  //   { index: 9, image: avatar9 },
  //   { index: 10, image: avatar10 },
  //   { index: 11, image: avatar11 },
  //   { index: 12, image: avatar12 },
  //   { index: 12, image: avatar13 },
  //   { index: 14, image: avatar14 },
  //   { index: 15, image: avatar15 },
  //   { index: 16, image: avatar16 },
  //   { index: 17, image: avatar17 },
  //   { index: 18, image: avatar18 },
  //   { index: 19, image: avatar19 },
  //   { index: 20, image: avatar20 },
  //   { index: 21, image: avatar21 },
  //   { index: 22, image: avatar22 },
  //   { index: 23, image: avatar23 },
  //   { index: 24, image: avatar24 },
  // ]

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await getListBeneficary(id, token)
        if (response?.success === true) {
          setPerson(response?.beneficiaries)
          setLoading(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user, isAdded])

  if (!loading) return <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />

  return (
    <div className="flex items-center gap-4">
      <div className="cursor-pointer  mt-3">
        <img src={iconAddBnf} className=" object-cover" width={52} height={52} onClick={openModal} />
        <div className="text-[14px] sm:text-[15px] text-center font-normal text-blue-950">Ajouter</div>
      </div>
      <ModalBeneficiary isOpen={isModalOpen} closeModal={closeModal}>
        <ModalAddBeneficiary
          // setBeneficiaryData={setPerson}
          closeModal={closeModal}
          // newImage={newImage}
          // setNewImage={setNewImage}
          // setNewImageSelected={setNewImageSelected}
          setIsAdded={() => setIsAdded(!isAdded)}
          // selectedImageId={selectedImageId}
          // setSelectedImageId={setSelectedImageId}
          // dataAvatar={dataAvatar}
        />
      </ModalBeneficiary>

      <div className="flex items-center overflow-x-auto w-full mx-auto">
        {person.map((item: any, index: number) => (
          <div className="flex flex-col p-2 rounded-lg mt-[10px]" key={index}>
            <div className="w-12 h-12">
              <Avatar avatar={item.avatar}/>
            </div>
            <div className="text-[14px] sm:text-[15px] font-normal text-blue-950 text-center">
              {/* {item?.personalInfo?.firstName?.length > 5
                ? `${item?.personalInfo?.firstName}`.slice(0, 5)
                : `${item?.personalInfo?.firstName}`} */}
                {
                `${item?.personalInfo?.firstName}`.slice(0, 5)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContainerHome
