import * as React from 'react'
import PropTypes from 'prop-types'


interface BeneficiaryIconProps extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
  defaultStroke?: string;
  hoverStroke?: string;
}

const BeneficiaryIcon: React.FC<BeneficiaryIconProps> = ({
  isActive = false,
  defaultStroke = "#A5A5A5",
  hoverStroke = "#5754E5",
  ...props
}) => {
  const strokeColor = isActive ? hoverStroke : defaultStroke


  return (
    <svg 
      width="28" height="28" 
      viewBox="0 0 25 25" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6396 16.6665C22.4201 16.6665 23.0409 16.1754 23.5983 15.4885C24.7395 14.0825 22.8659 12.959 22.1514 12.4088C21.425 11.8494 20.614 11.5325 19.7917 11.4582M18.75 9.37484C20.1882 9.37484 21.3542 8.20891 21.3542 6.77067C21.3542 5.33243 20.1882 4.1665 18.75 4.1665" 
        stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

        strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M3.36168 16.6665C2.58115 16.6665 1.96034 16.1754 1.40291 15.4885C0.261814 14.0825 2.13534 12.959 2.84989 12.4088C3.57627 11.8494 4.38733 11.5325 5.20964 11.4582M5.73047 9.37484C4.29223 9.37484 3.1263 8.20891 3.1263 6.77067C3.1263 5.33243 4.29223 4.1665 5.73047 4.1665" 
        stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

        strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M6.26662 17.341C4.56691 15.6595 7.35758 14.3156 8.42193 13.6575C10.9178 12.1142 14.0848 12.1142 16.5807 13.6575C17.6451 14.3156 20.4357 15.6595 18.736 17.341" 
        stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.1472 5.72933C16.1472 7.74287 14.5149 9.37516 12.5013 9.37516C10.4878 9.37516 8.85547 7.74287 8.85547 5.72933C8.85547 3.71579 10.4878 2.0835 12.5013 2.0835C14.5149 2.0835 16.1472 3.71579 16.1472 5.72933Z" 
        stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"

        strokeWidth="1.5"/>
      <path d="M13.2186 15.1853L13.9518 16.6639C14.0518 16.8697 14.3184 17.0672 14.5434 17.105L15.8724 17.3276C16.7223 17.4704 16.9223 18.0921 16.3098 18.7054L15.2766 19.7472C15.1017 19.9236 15.0059 20.2638 15.0601 20.5075L15.3558 21.7971C15.5891 22.8178 15.0517 23.2127 14.156 22.6791L12.9103 21.9357C12.6853 21.8012 12.3145 21.8012 12.0854 21.9357L10.8396 22.6791C9.94808 23.2127 9.40651 22.8136 9.63974 21.7971L9.93557 20.5075C9.98974 20.2638 9.89391 19.9236 9.71891 19.7472L8.68568 18.7054C8.07745 18.0921 8.27328 17.4704 9.12318 17.3276L10.4521 17.105C10.673 17.0672 10.9396 16.8697 11.0396 16.6639L11.7729 15.1853C12.1729 14.3829 12.8228 14.3829 13.2186 15.1853Z" 
      stroke={strokeColor}
      className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
BeneficiaryIcon.propTypes = {
  defaultStroke: PropTypes.string,
    hoverStroke: PropTypes.string,
}
export default BeneficiaryIcon
