import React, { useContext, useEffect, useState } from 'react'
import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'
import imageTelecom from '../../assets/imageTelecom.svg'
import classNames from 'classnames'
import drapeauTunisie from '../../assets/drapeauTunisie.svg'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import CustomSelect from 'components/CustomSelect'
import imageOrangeRecharge from '../../assets/imageOrangeRecharge.svg'
import imageOoredooRecharge from '../../assets/imageOoredooRecharge.svg'
import imageOoredooMobile from '../../assets/imageOoredooMobile.svg'
import imageOrangeMobile from '../../assets/imageOrangeMobile.svg'
import imageTtMobile from '../../assets/imageTtMobile.svg'
import drapeauMaroc from '../../assets/drapeauMaroc.svg'
import MarocCarte from './marocCarte'
import UserContext from 'contexts/UserContext'
import RechargeContext from 'contexts/rechargeContext'
import localforage from 'localforage'

interface Props {
  onChange: (value: string) => void
  isOperateur: string
  onChangeStep: any
  produitMaroc: any
  onChangePrefix: (value: string) => void
  changePhoneNumber: (value: string) => void
  setOperateur: (value: string) => void
  prefix: any
  setPrefix: any
  functionTracks: any
  selectedValue: any
  setSelectedValue: any
}

const Beneficiare: React.FC<Props> = ({
  onChange,
  isOperateur,
  onChangeStep,
  onChangePrefix,
  changePhoneNumber,
  setOperateur,
  produitMaroc,
  prefix,
  setPrefix,
  functionTracks,
  selectedValue,
  setSelectedValue,
}) => {
  const { beneficiaries } = useContext(BeneficiaryContext)
  const [phoneNumber, setPhoneNumber] = useState<any>('')
  const [isValid, setIsValid] = useState(true)
  const [isClicked, setIsClicked] = useState(false)

  const [isChangePrefix, setIsChangePrefix] = useState(false)

  const { user } = useContext(UserContext)
  const { recharges, setRecharges } = useContext(RechargeContext)

  const options: { icon: any; value: string; label: string }[] = [
    { icon: drapeauTunisie, value: '+216', label: '+216' },
    { icon: drapeauMaroc, value: '+212', label: '+212' },
  ]

  const handleSelectChange = (selectedValue: any) => {
    if (selectedValue === '+212') {
      setPrefix('+212')
      setRecharges({ ...recharges, prefix: '+212' })
      setSelectedValue('+212')
      produitMaroc()
    }
    setSelectedValue(selectedValue)
    onChangePrefix(selectedValue)
    setPrefix(selectedValue)
    setOperateur('')
    setPhoneNumber('')
    setRecharges({
      ...recharges,
      phoneNumber: '',
      prefix: selectedValue,
      MontantDinar: '',
      MontantEur: '',
      MontantTnd: '',
      isOn: false,
      indexRechargeTN: -1,
      isOperateur:''
    })
   

    // setIsChangePrefix(!isChangePrefix)
  }

  const handleClick = () => {
    setIsClicked(!isClicked)
  }

  const handleInputChange = (event: any) => {
    const inputValueWithoutSpaces = event.target.value.replace(/\D/g, '')
    let isValidInput = inputValueWithoutSpaces
    if (selectedValue === '+216') {
      isValidInput = /^[0-9]{8}$/.test(inputValueWithoutSpaces)
    } else if (selectedValue === '+212') {
      isValidInput = /^[0-9]{9}$/.test(inputValueWithoutSpaces)
    }
    setPhoneNumber(inputValueWithoutSpaces)
    setRecharges({ ...recharges, phoneNumber: inputValueWithoutSpaces })

    setIsValid(isValidInput)
    changePhoneNumber(inputValueWithoutSpaces)
  }

  //get phone number and prefix from beneficiaries
  const initialNumber = beneficiaries?.personalInfo?.phone?.number
  const initialPrefix = beneficiaries?.personalInfo?.phone?.prefix.startsWith('+')
    ? beneficiaries?.personalInfo?.phone?.prefix
    : `${'+'}${beneficiaries?.personalInfo?.phone?.prefix}`

  useEffect(() => {
    if (initialNumber) {
      setPhoneNumber(initialNumber)
      setRecharges({ ...recharges, phoneNumber: initialNumber, prefix: initialPrefix })
      if (isOperateur && initialNumber) {
        setRecharges({
          ...recharges,
          phoneNumber: initialNumber,
          prefix: initialPrefix,
          isOperateur: '',
          MontantTnd: '',
          MontantDinar: '',
          MontantEur: '',
          isOn: false,
          indexRechargeTN: 0,
        })
      }
    }
  }, [initialNumber])

  useEffect(() => {
    if (!recharges) return
    if (recharges?.prefix === undefined || recharges?.prefix === '') {
      setRecharges({ ...recharges, prefix: selectedValue })
    } else if (recharges) {
      setPhoneNumber(recharges?.phoneNumber)
      setSelectedValue(recharges?.prefix || selectedValue)
      setPrefix(recharges?.prefix || selectedValue)
      onChange(recharges?.isOperateur || '')
    }
  }, [recharges, phoneNumber, isOperateur, selectedValue, prefix])

  // useEffect(() => {
  //   if (isChangePrefix) {
  //     setRecharges({
  //       ...recharges,
  //       phoneNumber: '',
  //       isOperateur: '',
  //       MontantTnd: '',
  //       MontantDinar: '',
  //       MontantEur: '',
  //       isOn: false,
  //       indexRechargeTN: 0
  //     })
  //     setPhoneNumber('')
  //     setOperateur('')
  //   }
  // }, [isChangePrefix])

  // useEffect(() => {
  //   if (isOperateur !== '') {
  //     setRecharges({
  //       ...recharges,
  //       MontantTnd: '',
  //       MontantDinar: '',
  //       MontantEur: '',
  //       isOn: false,
  //       indexRechargeTN: 0,
  //     })
  //   }
  // }, [isOperateur])

  // condition from prefix
  useEffect(() => {
    if (user?.user?.destinationDefaultCountry === 'MAC' && selectedValue !== '+212') {
      setSelectedValue('+212')
      setPrefix('+212')
      produitMaroc()
    } else if (user?.user?.destinationDefaultCountry === 'TUN' && selectedValue !== '+216') {
      setSelectedValue('+216')
      setPrefix('+216')
    }
  }, [user?.user?.destinationDefaultCountry])

  useEffect(() => {
    if (initialPrefix === '+216' && user?.user?.destinationDefaultCountry === 'MAC') {
      setSelectedValue('+216')
      setPrefix('+216')
    } else if (initialPrefix === '+212' && user?.user?.destinationDefaultCountry === 'TUN') {
      setSelectedValue('+212')
      setPrefix('+212')
      produitMaroc()
    }
  }, [initialPrefix])

  return (
    <div>
      <div className=" flex flex-col lg:mt-4 sm:mx-auto">
        <div className="flex gap-[12px] mx-auto mt-[23px] font-sans">
         
            <div className="flex flex-col gap-1">
              <div className="text-[#050708] text-[17px] sm:text-[14px] font-semibold">
                Préfixe <span className="text-red-500">*</span>
              </div>
              <CustomSelect
                options={options}
                value={options.find((item) => item.value === selectedValue)?.label || 'Préfixe'}
                onChange={handleSelectChange}
                icon={options.find((item) => item.value === selectedValue)?.icon}
                width="sm:w-[99px] w-[106px] xxs:w-[95px]"
                height="h-[50px] xs:h-[45px] sm:h-[45px]"
                background="bg-gray-100 rounded-xl"
                textSize="text-[15px] xs:text-[12px] sm:text-[12px]"
                heightDropdown="h-[100px] z-[1]"
                roundedIcon="rounded-0"
                showArrow
              />
            </div>

          <div className="flex flex-col gap-1 h-[75px] sm:w-[240px] ">
            <label className="text-[17px] xs:text-[15px] sm:text-[15px] text-[#050708] font-semibold">
              Numéro de téléphone
              <span className="text-red-500">*</span>
            </label>
            <input
              className=" bg-gray-100 border-1 w-[430px] 2xl:w-[300px] text-[15px] sm:w-[233px] sm:text-[12px]  sm:h-[45px] h-[50px] placeholder-[#1D3A70] text-[#1D3A70] rounded-xl p-5"
              type="text"
              value={phoneNumber}
              placeholder={'Numéro de téléphone'}
              name="phone"
              autoComplete="off"
              onChange={handleInputChange}
            />
            {!isValid && (
              <p className="text-red-500 pl-2 text-[12px]">
                {selectedValue === '+216'
                  ? 'Le numéro de téléphone doit contenir 8 chiffres.'
                  : 'Le numéro de téléphone doit contenir 9 chiffres.'}
              </p>
            )}
          </div>
        </div>

        {selectedValue === '+216' ? (
          <div className={`flex flex-col lg:flex-row mt-[47px] 2xl:mx-6 mx-3 `}>
            <div className='flex gap-6 md:text-center lg:flex-row justify-center'>
              <div className="flex flex-col items-center hover:cursor-pointer"
                onClick={() => {
                  setOperateur('TUTN'), onChange('Ooredoo')
                  setRecharges({
                    ...recharges,
                    isOperateur: 'Ooredoo',
                    MontantDinar: '',
                    MontantEur: '',
                    MontantTnd: '',
                    isOn: false,
                    indexRechargeTN: -1,
                  })
                }}
              >
                <img
                src={imageOoredooRecharge}
                width={200}
                height={133}
                className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
                />

                

                <div onClick={handleClick} className="relative mt-3 items-center">
                  <img
                    src={RadioFocus}
                    className="w-6 h-6"
                    // onClick={() => {
                    //   setOperateur('TUTN'), onChange('Ooredoo')
                    //   setRecharges({
                    //     ...recharges,
                    //     isOperateur: 'Ooredoo',
                    //     MontantDinar: '',
                    //     MontantEur: '',
                    //     MontantTnd: '',
                    //     isOn: false,
                    //     indexRechargeTN: -1,
                    //   })
                    // }}
                  />
                  {isOperateur === 'Ooredoo' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
                </div>
              </div>

              <div className="flex flex-col items-center hover:cursor-pointer"
                onClick={() => {
                  setOperateur('ORTN'), onChange('Orange')
                  setRecharges({
                    ...recharges,
                    isOperateur: 'Orange',
                    MontantDinar: '',
                    MontantEur: '',
                    MontantTnd: '',
                    isOn: false,
                    indexRechargeTN: -1,
                  })
                }}>
               
                <img
                  src={imageOrangeRecharge}
                  width={200}
                  height={133}
                  className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
                  />
             

                
                <div onClick={handleClick} className="relative mt-3 items-center">
                  <img
                    src={RadioFocus}
                    className="w-6 h-6"
                    
                  />
                  {isOperateur === 'Orange' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
                </div>
              </div>

              <div className="flex flex-col items-center hover:cursor-pointer"
                onClick={() => {
                  setOperateur('TWTN'), onChange('Telecom'), onChangePrefix(phoneNumber)
                  setRecharges({
                    ...recharges,
                    isOperateur: 'Telecom',
                    MontantDinar: '',
                    MontantEur: '',
                    MontantTnd: '',
                    isOn: false,
                    indexRechargeTN: -1,
                  })
                }}
              >
                <img
                  src={imageTelecom}
                  width={200}
                  height={133}
                  className="rounded-md sm:w-[180px] sm:h-[80px] object-cover"
                  />
                
                
                <div onClick={handleClick} className="relative mt-3 items-center">
                  <img
                    src={RadioFocus}
                    className="w-6 h-6"
                  />
                  {isOperateur === 'Telecom' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
                </div>
              </div>
            </div>
          </div>
        ) : selectedValue === '+212' ? (
          <>
            <MarocCarte
              setOperateur={setOperateur}
              onChange={onChange}
              handleClick={handleClick}
              isOperateur={isOperateur}
              onChangePrefix={onChangePrefix}
              phoneNumber={phoneNumber}
              isClicked={isClicked}
            />
          </>
        ) : null}
      </div>

      <div className={`flex gap-[16px] justify-center my-5 place-self-center sm:px-14 w-full`}>
        <button
          className={classNames(
            `text-[18px] text-[#FFF]  p-2 h-[56px]  items-center rounded-xl sm:w-[100%] xs:w-[100%] lg:w-[50%] w-[300px]`,
            selectedValue === '' ||
              phoneNumber === '' ||
              phoneNumber === undefined ||
              prefix === '' ||
              isOperateur === 'null' ||
              isOperateur === '' ||
              (selectedValue === '+212' && (phoneNumber.length < 9 || phoneNumber.length > 9)) ||
              (selectedValue === '+216' && (phoneNumber.length < 8 || phoneNumber.length > 8))
              ? 'bg-gray-400'
              : 'bg-[#4743C9]',
          )}
          onClick={() => {
            onChangeStep()
            functionTracks('1')
          }}
          disabled={
            selectedValue === '' ||
            phoneNumber === '' ||
            phoneNumber === undefined ||
            prefix === '' ||
            isOperateur === 'null' ||
            isOperateur === '' ||
            (selectedValue === '+212' && (phoneNumber.length < 9 || phoneNumber.length > 9)) ||
            (selectedValue === '+216' && (phoneNumber.length < 8 || phoneNumber.length > 8))
              ? true
              : false
          }
        >
          Suivant
        </button>
      </div>
    </div>
  )
}
export default Beneficiare
