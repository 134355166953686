import React, { useState, useContext, useEffect} from 'react'
import UserContext from 'contexts/UserContext'
// import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { UpdateForKyc } from 'helpers/api'
import { getAllCountries } from 'requests/countries'
import iconSelectInput from '../assets/iconSelectInput.svg'
import { getListBeneficary } from 'requests/benificary'
import PersonalInformationContainer from './personalInformationContainer/personalInformationContainer'
import { VerifAccount } from 'requests/transfer'
import { Spinner } from 'utils/loading'
import localforage from 'localforage'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {fr} from "date-fns/locale"

const Personalinformation: React.FC = () => {
  const { user, setUser } = useContext(UserContext)
  const token = user?.token?.accessToken
  const [selectedOptionPays, setSelectedOptionPays] = useState('')
  const paysNaissance = getAllCountries()
  const [isOpenPays, setIsOpenPays] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState('')

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [maxDate, setMaxDate] = useState(new Date())
  const handleSelectPays = (optionPays: any) => {
    setSelectedCountryCode(optionPays.code)
    setSelectedOptionPays(optionPays.frenshName)
    setIsOpenPays(false)
  }
  const minDate= new Date(1910,1,1)


  // Calculer la date maximale en fonction de l'âge
  useEffect(() => {
    const today = new Date()
    const maxDateOfBirth = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
    setMaxDate(maxDateOfBirth)
  }, [])

  const [person, setPerson] = useState([])

  const [loading, setLoading] = useState(false)

  const [isLink, setIsLink] = useState('')

  const [isVerified, setIsVerified] = useState(false)

  const [onVerif, setOnVerif] = useState(false)

  const fetchDataVerification = async () => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    try {
      const response = await VerifAccount(id, token)
      if (response?.success) {
        setIsLink(response?.documentUrl)
        setIsVerified(true)
        setLoading(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleClick = () => {
    if (user) {
      fetchDataVerification()
    }
  }

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await getListBeneficary(id, token)
        if (response?.success === true) {
          setPerson(response?.beneficiaries)
          setLoading(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user])

  const initialFormData = {
    dateNaissance: '',
    adresseResidance: '',
    ville: '',
    codePostal: '',
    selectedOptionPays: '',
  }

  const [formData, setFormData] = useState(initialFormData)

  const validationSchema = yup.object({
    dateNaissance: yup
      .date()
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Vous devez avoir au moins 18 ans.')
      .required('Ce champ est requis')
      .typeError(""),



    selectedOptionPays: yup
      .string()
      .required('Ce champ est requis')
      .test('', function (valuePays) {
        if (valuePays === undefined || valuePays.trim() === '') {
          return this.createError({ message: 'Ce champ est requis' })
        }
        return true
      }),
    adresseResidance: yup
      .string()
      .required('Ce champ est requis')
      .test('', function (valueAdresse) {
        if (valueAdresse === undefined || valueAdresse.trim() === '') {
          return this.createError({ message: 'Ce champ est requis' })
        }
        return true
      }),
    ville: yup
      .string()
      .required('Ce champ est requis')
      .test('', function (valueVille) {
        if (valueVille === undefined || valueVille.trim() === '') {
          return this.createError({ message: 'Ce champ est requis' })
        }
        return true
      }),
    codePostal: yup
      .string()
      .required('Ce champ est requis')
      .test('', function (valueCodePostal) {
        if (valueCodePostal === undefined || valueCodePostal.trim() === '') {
          return this.createError({ message: 'Ce champ est requis' })
        }
        return true
      }),
  })
  const handleKeyDown =(e : React.KeyboardEvent<HTMLInputElement>) => {
    if (!/^[0-9]$/.test(e.key)
      && e.key !== "Backspace" 
      &&e.key !== "ArrowLeft" 
      && e.key !== "ArrowRight")
    {
      e.preventDefault()
    }
  }

  const handleSubmit = (values: typeof initialFormData) => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    if (formik.isValid) {
      const personalInfo: any = {
        dateNaissance: values.dateNaissance,
        adresseResidance: values.adresseResidance,
        valuePaysSelect: values.selectedOptionPays,
        ville: values.ville,
        codePostal: values.codePostal,
        
      }
      setFormData(personalInfo)
      const bodyOfPersonalInfo = {
        ...formik.values,
        countryOfBirthCode: selectedCountryCode,
      }
      UpdateForKyc(id, token, bodyOfPersonalInfo)
        .then((response: any) => {
          if (response?.success) {
            setOnVerif(true)
            if (user) {
              setUser({ token: user.token, success: true, user: response.user }),
                localforage.setItem('auth', JSON.stringify({ token: user.token, success: true, user: response.user }))
            }
            setLoading(true)
          }
        })
        .catch((error) => {
          console.error('Error updating for KYC:', error)
        })
    } else {
      console.log('Le formulaire contient des erreurs')
    }

  }

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })



  function formatInputDate(value: string | Date) {
    if (value instanceof Date) {
      if (isNaN(value.getTime())) {
        console.error('Date invalide :', value)
        return ''
      }
      return value.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    }
  
    // Si l'entrée est une chaîne brute
    const cleaned = value.replace(/\D/g, '') 
    const formatted = cleaned.replace(
      /^(\d{2})(\d{1,2})?(\d{1,4})?$/,
      (match, day, month, year) => {
        if (year) return `${day}/${month}/${year}`
        if (month) return `${day}/${month}`
        return day
      }
    )
  
    return formatted
  }

  if (!loading) return <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />

  return (
    <div className="flex xs:flex-col  justify-around sm:gap-y-[27px] sm:mx-0">
      <div className="max-w-max xl:w-[500px] lg:ml-8 lg:w-full sm:w-[358px] sm:ml-[10px] sm:h-[450px] mx-auto">
        <div
          className={`sm:text-[25px] text-[30px]  my-5 sm:font-semibold font-bold ${
            user?.user?.birthDate ? 'mt-0' : 'mt-[50px]'
          }`}
        >
          <span className={`text-[#1D3A70] text-center ${user?.user?.birthDate ? 'hidden' : 'block'}`}>
            Complétez votre profil
          </span>
        </div>
        <div className={`text-[#6B7280] text-[14px] ${user?.user?.birthDate ? 'hidden' : 'block'}`}>
          Nous avons besoin de vous connaître un peu plus.
        </div>

        <form
          className="grid grid-cols-1 justify-center items-center sm:gap-[15px] gap-[24px]"
          onSubmit={formik.handleSubmit}
          
        >
          <div className="maw-w-max">
            {user?.user?.birthDate ? (
              <div>
                {isLink !== '' && isVerified !== false ? (
                  <iframe src={isLink} width="540" height="750"></iframe>
                ) : (
                  <PersonalInformationContainer handleClick={handleClick} />
                )}
              </div>
            ) : (
              <div>
                <div
                  className={`text-[#1D3A70] text-[18px] font-medium mt-5 ${
                    user?.user?.birthDate ? 'hidden' : 'block'
                  }`}
                >
                  Votre adresse
                </div>

                <div className="h-[80px] w-full">
                  <input
                    type="text"
                    placeholder="Adresse de résidence"
                    value={formik.values.adresseResidance}
                    name="adresseResidance"
                    className="rounded-xl bg-[#F9FAFB]  text-[#1D3A70] placeholder:text-[#9CA3AF] h-[56px] sm:p-3  sm:text-[14px] p-5 mt-1 w-full"
                    onChange={formik.handleChange}
                    autoComplete="off"
                    onBlur={formik.handleBlur('adresseResidance')}
                  />
                  {formik.touched.adresseResidance && formik.errors.adresseResidance ? (
                    <div className="text-red-500 text-[14px] sm:text-[13px]">{formik.errors.adresseResidance}</div>
                  ) : null}
                </div>

                <div className="flex items-center gap-4 mt-4 sm:gap-0">
                  <div className="mr-5 sm:mr-2 h-[80px]">
                    <input
                      type="text"
                      placeholder="Code postal"
                      value={formik.values.codePostal}
                      name="codePostal"
                      className="rounded-xl bg-[#F9FAFB] text-[#1D3A70]  placeholder:text-[#9CA3AF] p-5 sm:p-3  sm:text-[14px] h-[56px] w-full"
                      onChange={formik.handleChange}
                      autoComplete="off"
                      onBlur={formik.handleBlur('codePostal')}
                      onKeyDown={handleKeyDown}
                    />
                    <br />
                    {formik.errors.codePostal && formik.touched.codePostal && (
                      <div className="text-red-500 text-[14px] text-sm sm:text-[13px]">{formik.errors.codePostal}</div>
                    )}
                  </div>
                  <div className="h-[80px]">
                    <input
                      type="text"
                      placeholder="Ville"
                      value={formik.values.ville}
                      name="ville"
                      className="rounded-xl bg-[#F9FAFB] text-[#1D3A70] placeholder:text-[#9CA3AF] sm:p-3  sm:text-[14px] h-[56px] w-full p-5"
                      onChange={formik.handleChange}
                      autoComplete="off"
                      onBlur={formik.handleBlur('ville')}
                    />
                    <br />
                    {formik.errors.ville && formik.touched.ville && (
                      <span className="text-red-500 text-[14px] text-sm sm:text-[13px]">{formik.errors.ville}</span>
                    )}
                  </div>
                  <div className="ml-5 sm:ml-2 h-[80px]">
                    <input
                      type="text"
                      placeholder={user?.user.address.country.name}
                      value={user?.user.address.country.name}
                      name="pays"
                      onChange={(e) => {
                        formik.setFieldValue('pays', e.target.value)
                      }}
                      className="rounded-xl bg-[#F9FAFB] text-[#1D3A70] placeholder:text-[#9CA3AF] sm:p-3  sm:text-[14px] h-[56px] p-5 w-full"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>

                <div className=" text-[#1D3A70] text-[18px] mt-4 font-medium">Vos informations personnelles</div>
                <div className="flex items-center">
                  <div className="w-full mr-5 sm:mr-3 h-[100px] sm:h-[85px]">
                    <div className="relative inline-block mt-1 w-full">
                      <input
                        type="text"
                        className="p-5 h-[56px] rounded-xl bg-[#F9FAFB] sm:p-3  sm:text-[14px] text-[#1D3A70]  placeholder:text-[#9CA3AF] w-full cursor-pointer"
                        placeholder="Pays de naissance"
                        onClick={() => setIsOpenPays(!isOpenPays)}
                        value={selectedOptionPays}
                        onChange={(e) => {
                          formik.setFieldValue('selectedOptionPays', e.target.value), handleSelectPays(e.target.value)
                        }}
                        name="selectedOptionPays"
                        onBlur={formik.handleBlur('selectedOptionPays')}
                        autoComplete="off"
                        readOnly
                      />

                        <img
                        src={iconSelectInput}
                        width={20}
                        height={20}
                         onClick={() => setIsOpenPays(!isOpenPays)}
                          className=" absolute top-5 right-0 mr-4 cursor-pointer" 
                        />

                      <div
                        className={`absolute top-full left-0 z-10 min-h-auto max-h-[150px] rounded-lg w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
                          isOpenPays ? 'block' : 'hidden'
                        }`}
                      >
                        {paysNaissance.map((paysList: any, index: number) => (
                          <div
                            key={index}
                            className="p-2 cursor-pointer hover:bg-[#F5F5F5] hover:text-blue-900 "
                            onClick={() => {
                              formik.setFieldValue('selectedOptionPays', paysList.frenshName)
                              handleSelectPays(paysList)
                            }}
                          >
                            {paysList.frenshName}
                          </div>
                        ))}
                      </div>
                    </div>
                    {formik.touched.selectedOptionPays && formik.errors.selectedOptionPays ? (
                      <div className="text-red-500 text-[14px] sm:text-[13px]">{formik.errors.selectedOptionPays}</div>
                    ) : null}
                  </div>

                  <div className="h-[100px] sm:h-[85px] w-full">
                    <div className="relative">
                      <DatePicker
                      
                        onBlur={formik.handleBlur('dateNaissance')}
                        selected={selectedDate}
                        placeholderText='jj/mm/aaaa'
                        dateFormat="dd/MM/yyyy"
                        autoComplete='off'
                        locale={fr}
                      
                        onChange={(e) => {
                          if (e && !isNaN(e.getTime())) {
                            setSelectedDate(e)
                            formik.setFieldValue('dateNaissance', e)

                          } else {
                            console.error('Date sélectionnée invalide:', e)
                          }
                        }}

                        name="dateNaissance"
                        wrapperClassName="relative w-full "
                        className="w-full rounded-xl bg-[#F9FAFB] text-[#1D3A70] placeholder:text-[#9CA3AF] sm:p-3 sm:text-[14px] h-[56px] mt-1 p-5 cursor-pointer"
                        calendarClassName="shadow-lg custom-calendar h-[270px] "
                        showIcon
                        toggleCalendarOnIconClick
                        calendarIconClassname="absolute bottom-3 right-3 cursor-pointer"
                        maxDate={maxDate}
                        minDate={minDate}
                      
                        // customInput={
                        //   <input
                        //     type="text"
                        //     name="dateNaissance"
                        //     maxLength={10}

                        //     value={formik.values.dateNaissance}
                        //     onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                         
                        //       const formattedValue = formatInputDate(e.target.value)
                        //       console.log('formattedValue',formattedValue)
                        //       formik.setFieldValue("dateNaissance", formattedValue)
                        //     }}
                        //   />                         
                        // }                       
                      />
                      
                    </div>
                    {formik.errors.dateNaissance && formik.touched.dateNaissance && (
                      <div className="text-red-500 text-[14px] text-sm sm:text-[13px]">
                        {formik.errors.dateNaissance}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-center mt-8 sm:mt-4">
              <button
              onClick={()=>{
                if(formik.isValid ){
                  setOnVerif(true)
                }

              }}

                type="submit"
                value="submit"
                disabled={user?.user?.birthDate !== undefined}
                className={`text-white  w-1/2 rounded-xl  h-[60px]  sm:p-4
                  ${ formik.isSubmitting && formik.isValid && onVerif  ? 'bg-gray-400' : 'bg-[#4743C9]'}
                  ${user?.user?.birthDate ? 'hidden' : 'block'}
                
                `}
               
              >
                {/*    ${user?.user?.birthDate ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#4743C9]'}
                ${user?.user?.birthDate ? 'hidden' : 'block'}
                 */}
                 
                { onVerif && (  <svg
                    className="w-6 h-6 mx-auto animate-spin text-white "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-50"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.971 7.971 0 014 12H2c0 2.21.896 4.209 2.344 5.656l1.656-1.365z"
                    ></path>
                  </svg>)
                       }
                       Continuer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default Personalinformation
