import React, { useContext, useEffect, useState } from 'react'
import Beneficiaire from './rechargeContainer/beneficiare'
import Step from '../components/Step'
import Offre from './rechargeContainer/offre'
import { Products, RechargeMobile, tracks } from 'requests/transfer'
import UserContext from 'contexts/UserContext'
import PaimentContainer from './rechargeContainer/paimentContainer'
import RecapComponent from 'components/recapComponent'
import { PaymentConfirmed } from 'requests/paiement'
import RechargeContext from 'contexts/rechargeContext'

export default function Recharge() {
  const { recharges } = useContext(RechargeContext)
  const nomstep = ['Bénéficiaire', 'Offre', 'Paiement']
  const [numStep, setNumStep] = useState(0)
  const [isOperateur, setIsOperateur] = useState('null')
  const [isVal2, setVal2] = useState('0')
  const [isVal3, setVal3] = useState('0')

  const [product, setProduct] = useState<any>([])
  const [productMaroc, setProductMaroc] = useState<any>([])

  const [loading, setLoading] = useState(false)
  const { user } = useContext(UserContext)

  const [rechargeMobileCarte, setRechargeMobileCarte] = useState<any>([])
  const [rechargeCarte, setRechargeCarte] = useState(rechargeMobileCarte)

  //props beneficiaires

  const [selectedValue, setSelectedValue] = useState<any>('')
  const [prefix, setPrefix] = useState<any>('')

  const [operateur, setOperateur] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  //props offre
  const [montantTnd, setMontantTnd] = useState('')
  const [montantDinar, setMontantDinar] = useState('')
  const [montantEur, setMontantEur] = useState('')
  const [skuCode, setSkuCode] = useState('')
  //props payment
  const [numCarte, setNumCarte] = useState('')
  const [codeCVC, setCodeCVC] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  //props payment invoices

  const [clientSecret, setClientSecret] = useState('')
  const [rechargeId, setRechargeId] = useState('')
  const produitMaroc = async () => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    try {
      const response = await Products(id, token, 'MA')
      // if (response?.success === true) {
      //   setProductMaroc(response.products
      //     ?.filter((v:any) => v?.Maximum?.ReceiveValue > 6)
      //     ?.filter((vv:any) => vv?.Benefits[1] === 'Minutes')
      //   )
      // }

      if (response?.success === true) {
        const sortedProducts = response.products
          ?.filter((v: any) => v?.Maximum?.ReceiveValue > 6)
          ?.filter((vv: any) => vv?.Benefits[1] === 'Minutes')
          ?.sort((a: any, b: any) => b.Maximum.ReceiveValue - a.Maximum.ReceiveValue) // Sorting in descending order
        setProductMaroc(sortedProducts)
      }
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await Products(id, token, 'TN')
        // if (response?.success === true) {
        //   setProduct(response.products?.filter((v:any) => v?.Maximum?.ReceiveValue > 6))
        //   setLoading(true)
        // }
        if (response?.success === true) {
          const sortedProducts = response.products
            ?.filter((v: any) => v?.Maximum?.ReceiveValue > 6)
            ?.sort((a: any, b: any) => b.Maximum.ReceiveValue - a.Maximum.ReceiveValue) // Sorting in descending order
          setProduct(sortedProducts)
          setLoading(true)
        }
      } catch (error) {
        console.error(error)
      }
      try {
        produitMaroc()
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user])

  const initialNumber = recharges?.phoneNumber

  const [buttonClicked, setButtonClicked] = useState(false)
  const [selectionCount, setSelectionCount] = useState(0)

  const FNPayer = async () => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    const dataRecharge = {
      userId: id,
      SkuCode: skuCode ? skuCode : recharges?.SkuCode,
      SendValue: montantEur,
      SendCurrencyIso: montantTnd,
      AccountNumber: `${phoneNumber || initialNumber}`,
      DistributorRef: operateur ? operateur : recharges?.isOperateur,
      platform: 'web'
    }
    try {
      const response = await RechargeMobile(id, token, dataRecharge)
      if (response?.success === true) {
        setClientSecret(response?.client_secret)
        setRechargeId(response?.recharge_id)
      } else {
        console.error('response failed', response)
      }
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }

  const confirmPayment = async () => {
    const token = user?.token?.accessToken

    const body = {
      recharge_id: rechargeId,
    }
    try {
      const response = await PaymentConfirmed(body, token)
      if (response?.success === true) {
        console.log('response', response)
      } else {
        console.error('response failed', response)
      }
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }

  const [trackId, setTrackId] = useState('')

  const functionTracks = async (stepNumber: string) => {
    const id = user?.user?._id
    let dataTracks = {
      label: 'recharge',
      step: stepNumber,
      userId: id,
    }
    if (stepNumber !== '0') {
      const newDataTracks = {
        ...dataTracks,
        trackId: trackId,
      }
      dataTracks = newDataTracks
    }
    //console.log('tracks: ', dataTracks)
    try {
      const response = await tracks(dataTracks)
      if (response?.success === true) {
        setTrackId(response?.trackId)
        //console.log('response tracks:', response)
      } else {
        console.error('response failed', response)
      }
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    functionTracks('0')
  }, [])

  return (
    <div
      className={`flex justify-between xl:items-center  lg:flex-col sm:mx-auto
      ${numStep === 1 || numStep === 2 ? '3xl:gap-0' : ''}
    `}
    >
      <div className="flex flex-col items-center mx-auto lg:mt-[15px] ">
        <div className={`h-[200px] lg:h-full  `}>
          <div className="md:text-[25px] text-center md:ml-0 md:mr-[35px] sm:mr-0 xl:text-center text-[#1D3A70] text-[35px] font-semibold sm:mt-4 ">
            Recharge mobile
          </div>
          <div className="flex mt-[25px] sm:mt-4  ">
            {nomstep.map((item, index) => (
              <Step key={index} nomStep={item} numStep={index + 1} isActive={index <= numStep} lastStep={index < 2} />
            ))}
          </div>
        </div>

        {numStep === 0 && (
          <Beneficiaire
            isOperateur={isOperateur}
            onChange={(value: string) => setIsOperateur(value)}
            onChangeStep={() => setNumStep(1)}
            onChangePrefix={(value: string) => setPrefix(value)}
            changePhoneNumber={(value: string) => setPhoneNumber(value)}
            setOperateur={(value: string) => setOperateur(value)}
            produitMaroc={produitMaroc}
            prefix={prefix}
            setPrefix={(value: string) => setPrefix(value)}
            functionTracks={functionTracks}
            selectedValue={selectedValue}
            setSelectedValue={(value: string) => setSelectedValue(value)}
          />
        )}

        {numStep === 1 && (
          <Offre
            isOoredoo={isOperateur === 'Ooredoo'}
            isOrange={isOperateur === 'Orange' ? true : false}
            isTelecom={isOperateur === 'Telecom' ? true : false}
            isOrangeMaroc={isOperateur === 'OrangeMaroc'}
            isTTMaroc={isOperateur === 'TTMaroc' ? true : false}
            isInwi={isOperateur === 'Inwi' ? true : false}
            onChangeVal={(val2: string, val3: string) => {
              setVal2(val2)
              setVal3(val3)
            }}
            productMaroc={productMaroc}
            product={product}
            onChangeStep={() => setNumStep(2)}
            onBackChangeStep={() => setNumStep(0)}
            setMontantTnd={(value: string) => setMontantTnd(value)}
            setMontantEur={(value: string) => setMontantEur(value)}
            setSkuCode={(value: string) => setSkuCode(value)}
            setMontantDinar={(value: string) => setMontantDinar(value)}
            FNPayer={FNPayer}
            functionTracks={functionTracks}
            selectedValue={selectedValue}
            montantTnd={montantTnd}
            montantDinar={montantDinar}
            montantEur={montantEur}
            skuCode={skuCode}
          />
        )}
     
        {numStep === 2 && (
          <PaimentContainer
            isOoredoo={isOperateur === 'Ooredoo'}
            isOrange={isOperateur === 'Orange' ? true : false}
            isTelecom={isOperateur === 'Telecom' ? true : false}
            onChangeVal={(val2: string, val3: string) => {
              setVal2(val2)
              setVal3(val3)
            }}
            product={product}
            rechargeMobileCarte={rechargeMobileCarte}
            setRechargeMobileCarte={rechargeMobileCarte}
            rechargeCarte={rechargeCarte}
            setRechargeCarte={setRechargeCarte}
            ProviderCode={isOperateur}
            onBackChangeStep={() => setNumStep(1)}
            setNumCarte={(value: string) => setNumCarte(value)}
            setCodeCVC={(value: string) => setCodeCVC(value)}
            setMonth={(value: string) => setMonth(value)}
            setYear={(value: string) => setYear(value)}
            FNPayer={FNPayer}
            SendValue={montantEur}
            isOrangeMaroc={isOperateur === 'OrangeMaroc'}
            isTTMaroc={isOperateur === 'TTMaroc' ? true : false}
            isInwi={isOperateur === 'Inwi' ? true : false}
            productMaroc={productMaroc}
            onChangeStep={() => setNumStep(3)}
            clientSecret={clientSecret}
            confirmPayment={confirmPayment}
            buttonClicked={buttonClicked}
            selectionCount={selectionCount}
            functionTracks={functionTracks}
          />
        )}
      </div>

      <div className={`mt-[2rem] pr-5 lg:hidden`}>
        <RecapComponent
          prefix={prefix}
          phoneNumber={phoneNumber}
          operateur={operateur}
          montantEur={montantEur}
          montantTnd={montantDinar}
        />
      </div>
    </div>
  )
}
