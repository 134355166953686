import React, { ReactNode } from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[#7D7D7D] bg-opacity-35 z-50">
      <div className="flex flex-col items-center rounded-lg p-6 w-full max-w-lg mx-4  relative bg-[#474747]  bg-opacity-100">
        
        <div>{children}</div>
        <button className="mt-4 text-white  bg-[#7D7D7D] h-[44px] w-[44px] rounded-full flex items-center justify-center" onClick={onClose}>
          &#x2715;
        </button>
      </div>
    </div>
  )
}

export default Modal
