import * as React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

interface RechargeIconProps extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
  defaultStroke?: string;
  hoverStroke?: string;
}


const RechargeIcon: React.FC<RechargeIconProps>= ({
  isActive = false,
  defaultStroke = "#A5A5A5",
  hoverStroke = "#5754E5",
  ...props
}) => {
  const strokeColor = isActive ? hoverStroke : defaultStroke

  return (
    <svg 
    width="28" 
    height="28" 
    viewBox="0 0 25 25" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >
<path d="M16.6654 6.771C16.6005 4.98716 16.379 3.90651 15.6224 3.15134C14.5527 2.0835 12.8308 2.0835 9.38712 2.0835C5.94347 2.0835 4.22165 2.0835 3.15183 3.15134C2.08203 4.21917 2.08203 5.93784 2.08203 9.37516V15.6252C2.08203 19.0625 2.08203 20.7811 3.15183 21.849C4.22165 22.9168 5.94347 22.9168 9.38712 22.9168C12.8308 22.9168 14.5527 22.9168 15.6224 21.849C16.379 21.0938 16.6005 20.0132 16.6654 18.2293" 
  stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
         
  strokeWidth="1.5" strokeLinecap="round"/>
<path d="M8.33203 19.7915H10.4154" 
  stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
         
  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.668 12.4903V12.4997M20.8849 17.1782C22.141 15.9786 22.918 14.3213 22.918 12.4907C22.918 10.6602 22.141 9.00286 20.8849 7.80322M18.7513 10.147C19.3793 10.7468 19.7679 11.5754 19.7679 12.4907C19.7679 13.406 19.3793 14.2347 18.7513 14.8345" 
  stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
         
  strokeWidth="1.5" strokeLinecap="round"/>
<path d="M6.25 2.0835L6.34271 2.63977C6.54363 3.84526 6.64408 4.44801 7.05749 4.81479C7.48873 5.19739 8.10015 5.2085 9.375 5.2085C10.6499 5.2085 11.2612 5.19739 11.6925 4.81479C12.1059 4.44801 12.2064 3.84526 12.4073 2.63977L12.5 2.0835" 
  stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
         
  strokeWidth="1.5" strokeLinejoin="round"/>
<path d="M12.25 14.7628C11.7517 15.7039 10.845 16.3333 9.80917 16.3333C8.23646 16.3333 6.96154 14.8824 6.96154 13.0926V11.2407C6.96154 9.45093 8.23646 8 9.80917 8C10.845 8 11.7517 8.62944 12.25 9.57056M6 12.1667H10.0866" 
  stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]"
         
  strokeWidth="1.5" strokeLinecap="round"/>
</svg>
  )
}
RechargeIcon.propTypes = {
defaultStroke: PropTypes.string,
  hoverStroke: PropTypes.string,
}
export default RechargeIcon
