import * as React from 'react'
import PropTypes from 'prop-types'


interface TransfertCompteBancaireIconProps extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
  defaultStroke?: string;
  hoverStroke?: string;
}


const TransfertCompteBancaireIcon: React.FC<TransfertCompteBancaireIconProps>= ({
  isActive = false,
  defaultStroke = "#A5A5A5",
  hoverStroke = "#5754E5",
  ...props
}) => {
  const strokeColor = isActive ? hoverStroke : defaultStroke 
 
  return (
    <svg width="25" height="25" 
      viewBox="0 0 25 25" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
   
    <path d="M3.48438 16.8723L16.8479 3.50879M19.4072 11.516L17.116 13.8072M15.1606 15.7392L14.1411 16.7587" 
    stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]" 
    strokeWidth="1.5" strokeLinecap="round"/>
   
    <path d="M3.30565 16.8138C1.67416 15.1824 1.67416 12.5371 3.30565 10.9057L10.9042 3.30711C12.5357 1.67562 15.1809 1.67562 16.8123 3.30711L21.6917 8.18653C23.3233 9.81802 23.3233 12.4632 21.6917 14.0946L14.0932 21.6932C12.4617 23.3247 9.81655 23.3247 8.18506 21.6932L3.30565 16.8138Z" 
    stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]" 
    strokeWidth="1.5"/>
   
    <path d="M4.16797 22.9165H20.8346" 
    stroke={strokeColor}
        className="group-hover:stroke-[#5754E5] group-focus:stroke-[#5754E5]" 
    strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}
TransfertCompteBancaireIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}
export default TransfertCompteBancaireIcon
