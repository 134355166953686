import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import logoSignup from '../assets/logoMyEasy.svg'
import iconSelectInput from '../assets/iconSelectInput.svg'
import FrIcon from '../assets/flag-fr.png'
import GrcIcon from '../assets/grc.svg'
import ItaIcon from '../assets/ita.svg'
import NldIcon from '../assets/nld.svg'
import PrtIcon from '../assets/prt.svg'
import LuxIcon from '../assets/lux.svg'
import GerIcon from '../assets/ger.svg'
import DeuIcon from '../assets/deu.svg'
import AutIcon from '../assets/aut.svg'
import BelIcon from '../assets/bel.svg'
import ArrowDown from '../assets/arrowDown.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Signup } from 'helpers/api'
import ModalSignUp from './signUpContainer/modalSignUp'
import LogoConatiner from './signUpContainer/logoContainer'
import ImageSignUp from './signUpContainer/imageSignUp'
import InvisibleEye from '../assets/invisibleEye.svg'
import VisibleEye from '../assets/visibleEye.svg'
import imageLoginMobile from '../assets/imageLoginMobile.svg'
import newImageLogin from '../assets/newImageLogin.svg'
import plusIcon from '../assets/plusIcon.svg'
import closeIconParainage from '../assets/closeIconParainage.svg'
import { FindReferralCode } from 'requests/user'
import CustomSelect from 'components/CustomSelect'

const Home: React.FC = () => {
  const [email, setEmail] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [icon, setIcon] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [selectedOptiongenre, setSelectedOptiongenre] = useState('')

  const [isSuccess, setIsSuccess] = useState('')
  const [responseSuccess, setResponseSuccess] = useState(true)
  const [firstSubmit, setFirstSubmit] = useState(true)
  const [visible, setVisible] = useState(false)

  const [codeParinage, setCodeParinage] = useState('')

  const [messageCode, setMessageCode] = useState<string>('')

  const [apiCalled, setApiCalled] = useState(false)
  const [valuePrefixeChoix, setValuePrefixeChoix] = useState(false)

  const openModalSignUp = () => {
    setIsOpenModal(!isOpenModal)
  }

  const closeModalSignUp = () => {
    setTimeout(() => navigate('/login'), 500)
    setIsOpenModal(false)
  }

  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  const [isOpenCodeParrinage,setIsOpenCodeParrinage] = useState(false)


  const options = [
    { index: 0, code: 'female', name: 'Femme' },
    { index: 1, code: 'male', name: 'Homme' },
  ]

  
  const [isOpenPays, setIsOpenPays] = useState(false)

  const optionsPaysSelected: { icon: any; code: string; frenshName: string; prefix: string }[] = [
    { icon: FrIcon, frenshName: 'France', code: 'FRA', prefix: '+33' },
    { icon: DeuIcon, frenshName: 'Espagne', code: 'ESP', prefix: '+34' },
    { icon: AutIcon, frenshName: 'Autriche', code: 'AUT', prefix: '+43' },
    { icon: BelIcon, frenshName: 'Belgique', code: 'BEL', prefix: '+32' },
    { icon: GrcIcon, frenshName: 'Grèce', code: 'GRC', prefix: '+30' },
    { icon: ItaIcon, frenshName: 'Italie', code: 'ITA', prefix: '+39' },
    { icon: NldIcon, frenshName: 'Pays Bas', code: 'NLD', prefix: '+31' },
    { icon: PrtIcon, frenshName: 'Portugal', code: 'PRT', prefix: '+351' },
    { icon: LuxIcon, frenshName: 'Luxembourg', code: 'LUX', prefix: '+352' },
    { icon: GerIcon, frenshName: 'Allemagne', code: 'DEU', prefix: '+49' },
  ]

  //const optionsPays = getSenderCountries()

  const [selectedOptionPays, setSelectedOptionPays] = useState('')
  const [selectedPrefix, setSelectedPrefix] = useState('')
  const [selectedCountryCode, setSelectedCountryCode] = useState('')

  const [valuePrefixe, setValuePrefixe] = useState(false)

  function removePlusPrefix(prefix: string) {
    if (prefix.startsWith('+')) {
      return prefix.slice(1) // Retourne le préfixe sans le "+"
    }
    return prefix // Si le préfixe ne commence pas par "+", retourne tel quel
  }



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const functionfindCode = async () => {
    const code = codeParinage
    try {
      const response = await FindReferralCode(code)
      setApiCalled(true)
      if (response?.success === true) {
        setCodeParinage(response?.code)
        setMessageCode('true')
      } else {
        setMessageCode('false')
        console.error('response failed find Code Parinage', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (codeParinage && codeParinage.length === 8) {
      functionfindCode()
    }
  }, [codeParinage])

  const initialFormData = {
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    password: '',
    selectedOption: '',
    // valuePrefixe : '',
    checkbox: false,
  }

  const [formData, setFormData] = useState(initialFormData)

  const validationSchema = yup.object({
    // nom: yup
    //   .string()
    //   .matches(/^[a-zA-Z\u0600-\u06FF\s]+$/, 'Des lettres uniquement')
    //   .test('', function (value) {
    //     if (value === undefined) {
    //       return false
    //     }
    //     return !/[0-9~!@#$%^&*()_+{}:;<>,.?/-]/.test(value)
    //   })
    //   .required('Le nom est obligatoire'),

    nom: yup
      .string()
      .matches(/^[a-zA-Z\u0600-\u06FF\s]+$/, 'Des lettres uniquement')
      .test('valid-spaces', 'Ce champ est requis', value => {
        if (!value) return false
        {return !/^\s| {2,}/.test(value)} 
       
      })
      .test('at-least-two-letters', 'Ce champ doit contenir au moins deux lettres', value => {
        if (!value) return true 
        const letters = (value.match(/[a-zA-Z\u0600-\u06FF]/g) || []).length
        return letters >= 2 
      })
      .required('Ce champ est requis'),

    prenom: yup
      .string()
      .matches(/^[a-zA-Z\u0600-\u06FF\s]+$/, 'Des lettres uniquement')
      .test('valid-spaces', 'Ce champ est requis', value => {
        if (!value) return false
        {return !/^\s| {2,}/.test(value)} 
       
      })
      .test('at-least-two-letters', 'Ce champ doit contenir au moins deux lettres', value => {
        if (!value) return true 
        const letters = (value.match(/[a-zA-Z\u0600-\u06FF]/g) || []).length
        return letters >= 2 
      })
      .required('Ce champ est requis'),

    // telephone: yup
    //   .string()
    //   .required('Téléphone obligatoire')
    //   .matches(/^[0-9]+$/, 'Le numéro de téléphone invalide'),
    telephone: yup
      .string()
      .required('Ce champ est requis')
      .test('', function (valueTelephone) {
        if (valueTelephone === undefined || valueTelephone.trim() === '') {
          return this.createError({ message: 'Ce champ est requis' })
        }
        return true
      })
      .matches(/^[0-9]+$/, 'Le numéro de téléphone est invalide'),

    password: yup
      .string()
      .required('Ce champ est requis')
      .test('', function (valuePassword) {
        if (valuePassword === undefined || valuePassword.trim() === '') {
          return this.createError({ message: 'Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère spécial.' })
        }
        return true
      })
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z\d\s]).{8,16}$/,
        'Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère spécial.',
      ),
    email: yup
      .string()
      .required('Ce champ est requis')
      .test('', function (valueEmail) {
        if (valueEmail === undefined || valueEmail.trim() === '') {
          return this.createError({ message: 'Ce champ est requis' })
        }
        return true
      })
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9\u0600-\u06FF.-]+\.[a-zA-Z]{2,4}$/, "Votre adresse e-mail doit être valide (exemple@domain.com)"),
    
    selectedOption: yup.string().required('Ce champ est requis'),
    checkbox: yup.boolean().oneOf([true], 'Veuillez accepter les conditions générales'),

    // selectedOptionPays: yup.string().required('Ce champ est requis prefixe'),

  })
  const handleSubmit = (values: typeof initialFormData, { setSubmitting }:any) => {
    // const prefixForSignup =removePlusPrefix(values.perfixSelected)

    const credentials = {
      nom: values.nom,
      prenom: values.prenom,
      email: values.email,
      telephone: values.telephone,
      password: values.password,
      // valuePrefixe : values.valuePrefixe,
     
    }

    if (formik.isValid) {
      // const signup: any = {
      //   nom: values.nom,
      //   prenom: values.prenom,
      //   email: values.email,
      //   telephone: values.telephone,
      //   password: values.password,
      //   // valuePrefixe : values.valuePrefixe
        
      // }

      setFormData(initialFormData)
      const prefix = removePlusPrefix(selectedPrefix)

      const bodyOfSignup = {
        ...credentials,
        valueGender:selectedOption,
        prefix,
        contryCode: selectedOptionPays,
        valuePaysSelect:selectedCountryCode
      }


      Signup(bodyOfSignup).then((response: any) => {
        if (response?.success) {
          setFirstSubmit(false)
          openModalSignUp()
          
        } else if (response.code === 1007) {
          setIsSuccess(response?.code)
          
        } else {
          setResponseSuccess(response?.success)

        }
        setSubmitting(false)

      })
    } else {
      console.log('Nous sommes désolé une erreur est survenue. Merci de réessayer plus tard')
      setSubmitting(false)
     


    }
  }

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  const handleSelect = (option: any) => {
    setSelectedOption(option.code)
    setSelectedOptiongenre(option.name)

    setIsOpen(false)
  }


  const handleSelectPays = (optionPays: any) => {
    setSelectedOptionPays(optionPays.frenshName)
    setSelectedPrefix(optionPays.prefix)
    setSelectedCountryCode(optionPays.code)
    setIcon(optionPays.icon)
    setIsOpenPays(!isOpenPays)
    setValuePrefixe(true)
    setValuePrefixeChoix(true)
    // setValuePrefixe(optionPays.prefix)
    
  }

  return (
    
      <div className="flex lg:flex-col h-full" 
      onClick={()=>{ 
        if(isOpen){
         setIsOpen(false)}
         if (isOpenPays){setIsOpenPays(false)}
        }
      }>
        
         <img
          className={classNames('hidden lg:block lg:w-full p-[20px] ')}
          src={imageLoginMobile}
          alt=""
        />
        <div className="w-1/2 lg:w-full ">
       
            <LogoConatiner image={logoSignup} />
           
          <div className="mx-auto w-[450px] lg:w-full lg:px-[20px] ">
              {/* <div
                className={classNames(
                  `sm:text-[20px] 6xl:text-[18px] text-center xl:mt-auto md:mt-[50px] lg:text-[25px] xl:text-[18px] 2xl:text-[20px] text-[30px] my-5 sm:font-semibold font-bold mt-[30px]`,
                  isInputClicked && 'sm:mt-0',
                )}
              > */}
                <h1 className="text-[#1D3A70] text-left text-[25px] font-bold mt-16 lg:mt-0">Créez votre Compte                 
                  <span className="text-[#5754E5]"> My Easy Transfer</span>
                </h1>
              
              {/* </div> */}

              {isSuccess !== '' && (
                <div className="text-[#EC1C24] text-[13px] ">Un compte existe déjà avec cette adresse e-mail.</div>
              )}

              {responseSuccess === false && (
                <div className="text-[#EC1C24] text-[13px]  leading-0">
                  Nous sommes désolé une erreur est survenue. Merci de réessayer plus tard.
                </div>
              )}

              <form
                className=" flex flex-col sm:gap-[15px] gap-6 my-6 place-items-center h-full"
                onSubmit={formik.handleSubmit}
              >
                
                  <div className="flex gap-3 h-[70px]  w-full justify-between">
                    <div className='w-1/2'>
                      <input
                        type="text"
                        placeholder="Nom"
                        value={formik.values.nom}
                        name="nom"
                        className="rounded-xl bg-[#F9FAFB] w-full placeholder:text-[#9CA3AF] text-[#5754E5] p-4"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur('nom')}
                      />
                      {formik.errors.nom && formik.touched.nom && (
                        <div className="text-[#EC1C24] text-[12px] ">
                          {formik.errors.nom}
                        </div>
                      )}
                    </div>

                    <div className='w-1/2'>
                      <input
                        type="text"
                        placeholder="Prénom"
                        value={formik.values.prenom}
                        name="prenom"
                        className="rounded-xl bg-[#F9FAFB] w-full  placeholder:text-[#9CA3AF] text-[#5754E5] p-4"
                        onChange={formik.handleChange}
                        autoComplete="off"
                        onBlur={formik.handleBlur('prenom')}
                      />
                     
                      {formik.errors.prenom && formik.touched.prenom && (
                        <div className="text-[#EC1C24] text-[12px]">{formik.errors.prenom}</div>
                      )}
                    </div>
                  </div>

                  <div className="relative inline-block w-full h-[70px] ">
                    <input
                      type="text"
                      className="p-4 bg-[#F9FAFB] w-full rounded-xl placeholder:text-[#9CA3AF] text-[#5754E5] cursor-pointer"
                      placeholder="Genre"
                      onClick={() => setIsOpen(!isOpen)}
                      value={selectedOptiongenre}
                      // onChange={(e) => {
                      //   formik.setFieldValue('selectedOption', e.target.value)
                      //   handleSelect(e.target.value)
                      // }}
                      autoComplete="off"
                      name="selectedOption"
                      
                      onBlur={formik.handleBlur('selectedOption')}
                      readOnly
                    />
                    <div className="absolute top-0 right-0 p-4 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                      <img src={ArrowDown} height={20} width={20}  />
                    </div>

                    <div
                      className={`absolute top-0left-0 z-10 min-h-auto rounded-lg max-h-[150px] w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
                        isOpen ? 'block' : 'hidden'
                      }`}
                    >
                      {options.map((option) => (
                      
                        <div
                          key={option.name}
                          className={ classNames(`${selectedOption === option.name && 'bg-[#F5F5F5]'} py-2 pl-3 cursor-pointer hover:bg-gray-100`)}
                          onClick={() => {
                            formik.setFieldValue('selectedOption', option.code)
                            handleSelect(option)
                          }}
                        >
                          <span className="text-[#1D3A70] text-[15px] font-semibold">{option.name}</span>
                        </div>
                      ))}
                    </div>
                    {formik.touched.selectedOption && formik.errors.selectedOption ? (
                      <div className="text-[#EC1C24] py-1 text-[12px]">{formik.errors.selectedOption}</div>
                    ) : null}
                  </div>

                  <div className="w-full h-[70px]">
                    <input
                      type="text"
                      placeholder="Adresse e-mail"
                      value={formik.values.email}
                      name="email"
                      className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full"
                      onChange={(e: any) => {
                        formik.handleChange(e), setEmail(e.target.value),setIsSuccess('')
                        
                      }}
                      onBlur={formik.handleBlur('email')}
                      
                    />
                    
                    {formik.errors.email && formik.touched.email && (
                      <div className="text-[#EC1C24] text-[12px] ">
                        {formik.errors.email}
                        
                      </div>
                    )}
                  </div>

                <div className="flex w-full gap-2">

                  <div className="relative inline-block  gap-2 h-[70px] w-[360px] sm:w-full  ">
                    <div
                      className="px-3 py-4 bg-[#F9FAFB] w-full rounded-xl placeholder:text-[#9CA3AF] text-[#5754E5] cursor-pointer"
                      onClick={() => {setIsOpenPays(!isOpenPays)
                        setValuePrefixe(true)}
                      }

                    >{ selectedOptionPays ?
                       <div className='flex gap-2'>
                      <img
                        height={25}
                        width={25}
                          src={icon}
                          className="rounded-full"
                      />
                        <span>{selectedPrefix } </span>
                       </div>
                       :
                       <span className='text-[#9CA3AF]'>Préfixe</span>
                    }
                    
                    </div>
                   
                    <div className="absolute flex top-0 right-0 p-4 cursor-pointer" onClick={() => setIsOpenPays(!isOpenPays)}>
                      <img className='' src={ArrowDown} height={20} width={20}  />
                    </div>

                    <div
                      className={`absolute left-0 z-10 min-h-auto rounded-lg max-h-[150px] w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
                        isOpenPays ? 'block' : 'hidden'
                      }`}
                    >
                      {optionsPaysSelected.map((optionPays) => (
                      
                        <div
                          key={optionPays.code}
                          className={ classNames(`${selectedOptionPays === optionPays.frenshName && 'bg-[#F5F5F5]'} py-2 pl-3 cursor-pointer hover:bg-gray-100 flex gap-2 `)}
                          onChange={formik.handleChange}
                          
                          onClick={() => {
                            handleSelectPays(optionPays)
                          }}
                        >
                        <img src={optionPays.icon} className="w-6 h-6 rounded-full " /> <span className='text-[#6B7280] '>{optionPays.prefix}</span> <span className='text[#1D3A70] sm:hidden'>{optionPays.frenshName}</span>

                        </div>
                      ))}
                    </div>
                    {valuePrefixe && selectedOptionPays === '' && (
                      <div className="text-[#EC1C24] text-[12px] mt-1">
                        Ce champ est requis
                      </div>
                    )}
                  </div>
                 
                    
                    <div className="h-[70px] sm:ml-3 w-full">
                      <input
                        type="text"
                        placeholder="Numéro de téléphone"
                        value={formik.values.telephone}
                        name="telephone"
                        className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur('telephone')}
                        autoComplete="off"
                      />
                      {formik.errors.telephone && formik.touched.telephone && (
                        <div className="text-[#EC1C24] text-[12px]">{formik.errors.telephone}</div>
                      )}
                    </div>
                  </div>
                  <div className="relative h-[70px] w-full">
                    <input
                      className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full"
                      name="password"
                      placeholder="Mot de passe"
                      type={visible ? 'text' : 'password'}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur('password')}
                      autoComplete="off"
                    />
                    <img
                      src={InvisibleEye}
                      alt=""
                      className={classNames(
                        'absolute cursor-pointer',
                        !visible ? 'top-7 right-3 transform -translate-y-1/2 cursor-pointer' : 'hidden',
                      )}
                      onClick={() => setVisible(!visible)}
                    />
                    <img
                      src={VisibleEye}
                      alt=""
                      className={classNames(
                        'absolute cursor-pointer',
                        visible ? 'top-7 right-3 transform -translate-y-1/2 cursor-pointer' : 'hidden',
                      )}
                      onClick={() => setVisible(!visible)}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <div className="text-[#EC1C24] text-[12px]">{formik.errors.password}</div>
                    )}
                  </div>

                  <div className=" w-full cursor-pointer">
                      <div className='flex justify-between items-center mb-4' onClick={() => setIsOpenCodeParrinage(!isOpenCodeParrinage)}>
                        <div className='text-[#1D3A70] font-bold  '>Code parrainage (Optionnel)</div>
                        <img className={`${isOpenCodeParrinage ? '': 'rotate-45'} cursor-pointer `}  src={closeIconParainage} alt=''/>
                      </div>

                    { isOpenCodeParrinage &&
                    <div>
                      <div className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full flex items-center justify-between">

                      <input
                        className='bg-transparent focus:border-transparent focus:outline-none w-full'

                        // className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full"
                        name="codeParinage"
                        value={codeParinage}
                        placeholder="Code Parrainage"
                        type="text"
                        maxLength={8}
                        autoComplete="off"
                        onChange={(e: any) => {
                          setCodeParinage(e.target.value)
                        }}
                      />
                      <div onClick={functionfindCode} className=' hover:cursor-pointer'>Appliquer</div>
                    </div>
                    
                      {apiCalled &&
                        (messageCode === 'false' ? (
                          <div className="text-[#EC1C24] text-[12px] py-2 ">Code de parrainage invalide</div>

                        ) : (
                          <div className="text-green-700 text-[12px] py-2">Code de parrainage valide</div>

                        ))}
                    </div>
                      }
                    
                  </div>
              <div className=' w-full h-[50px] '>
                <div className="flex justify-between justify-items-center  w-full">
                    <div className='flex items-center gap-2 '>
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={formik.values.checkbox}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="checkbox"
                        autoComplete="off"
                        className="w-4 h-4 text-[#5754E5] bg-gray-100 border-[#5754E5] rounded focus:ring-[#D8D0FF] dark:focus:ring-[#5754E5]  dark:ring-[#5754E5] focus:ring-2 cursor-pointer"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="text-[15px] sm:text-[11px] font-semibold"
                      >
                        <a
                          href="https://www.myeasytransfer.com/cgv-cgu-Myeasytransfer"
                          className="underline text-[#5754E5]"
                        >
                          J’accepte les conditions générales*
                        </a>
                            
                      </label>
                    </div>

                  <div className="text-[15px]  sm:text-[11px] underline text-[#5754E5]  font-semibold ">
                    <Link to="/login">J&apos;ai un compte</Link>
                  </div>
                </div>
                {formik.errors.checkbox && formik.touched.checkbox && (
                          <div className="text-[#EC1C24] sm:w-[200px] sm:text-[9px] text-[12px] pl-6 py-1">
                            {formik.errors.checkbox}
                          </div>
                        )}
                  
                </div>
                <button
                  type="submit"
                  value="Submit"
                  disabled={formik.isSubmitting || !formik.isValid || !valuePrefixeChoix }
                  className={`text-white w-full rounded-xl mb-10 p-4  ${
                    formik.isSubmitting || !formik.isValid || !valuePrefixeChoix ?'bg-gray-400 cursor-not-allowed' : 'bg-[#4743C9]'
                  }`}
                >
                  S’inscrire
                </button>
             
            </form>
            
          </div>
          <ModalSignUp isOpen={isOpenModal} closeModal={closeModalSignUp} email={email} />
          
        </div>
        <ImageSignUp image={newImageLogin} />
       
      </div>
    
  )
}
export default Home
