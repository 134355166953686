import React, { useContext, useEffect, useState } from 'react'
import iconCloseModalRecharge from '../../assets/iconCloseModalRecharge.svg'
import classNames from 'classnames'
import flagMaroc from '../../assets/flagMaroc.svg'
import flagTunisie from '../../assets/flagTunisie.svg'
import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'
import UserContext from 'contexts/UserContext'
import { useLocation } from 'react-router'

interface props {
  isOpen: boolean
  closeModal: () => void
  onChange: (value: string) => void
  setOperateur: (value: string) => void
  isOperateur: string
  updateCountryUser: any
  setDestinationDefaultCountry: (value: string) => void
}

const ModalSowCountry: React.FC<props> = ({
  isOpen,
  closeModal,
  onChange,
  isOperateur,
  setOperateur,
  updateCountryUser,
  setDestinationDefaultCountry,
}) => {
  const location = useLocation()
  if (
    !isOpen ||
    location.pathname === '/login' ||
    location.pathname === '/signup' ||
    location.pathname === '/forgotPassword' ||
    location.pathname.includes('/reset-pass') ||
    location.pathname.includes('/activation')
  )
    return null

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(!isClicked)
  }

  return (
    <div
      className={classNames(
        'fixed justify-center items-center flex flex-col inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm',
      )}
    >
      <div className="my-6 mx-auto w-auto">
        <div
          className={classNames(
            'border-0 rounded-xl pb-8 pt-5 px-5 sm:px-8 md:px-8 sm:py-8 md:py-8 shadow-xl relative xl:py-4 flex flex-col w-auto bg-white',
          )}
        >
          <div className="text-center py-3 text-[18px] text-[#666666]">Choisissez votre pays de destination</div>
          <div className="p-5 flex gap-[35px]">
            <div className="flex flex-col items-center hover:cursor-pointer">
              <img
                src={flagTunisie}
                width={150}
                height={100}
                alt="pays de destination: Tunisie"
                onClick={() => {
                  setOperateur('TN'), onChange('Tunisie')
                  setDestinationDefaultCountry('TUN')
                }}
                className="rounded-md object-cover"
              />

              <div onClick={handleClick} className="relative mt-3 items-center">
                <img
                  src={RadioFocus}
                  className="w-6 h-6"
                  onClick={() => {
                    setOperateur('TN'), onChange('Tunisie')
                    setDestinationDefaultCountry('TUN')
                  }}
                />
                {isOperateur === 'Tunisie' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
              </div>
            </div>

            <div className="flex flex-col items-center hover:cursor-pointer ml-0">
              <img
                src={flagMaroc}
                alt="pays de destination: Maroc"
                width={150}
                height={100}
                onClick={() => {
                  setOperateur('MC'), onChange('Maroc')
                  setDestinationDefaultCountry('MAC')
                }}
                className="rounded-md object-cover"
              />

              <div onClick={handleClick} className="relative mt-3 items-center">
                <img
                  src={RadioFocus}
                  className="w-6 h-6"
                  onClick={() => {
                    setOperateur('MC'), onChange('Maroc')
                    setDestinationDefaultCountry('MAC')
                  }}
                />
                {isOperateur === 'Maroc' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              className={` rounded-xl h-[50px] mt-8 text-white text-[15px] w-[300px] font-medium
            ${isOperateur === '' ? 'bg-gray-400' : 'bg-[#4743C9]'}
            `}
              disabled={isOperateur === ''}
              onClick={updateCountryUser}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalSowCountry
