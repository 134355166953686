import React, { useState } from 'react'
import scanPhoto from '../../assets/scanPhoto.svg'
import iconLock from '../../assets/iconLock.svg'

interface props {
  handleClick: any
}

const PersonalInformationContainer: React.FC<props> = ({ handleClick }) => {
  const[onVerif, setOnVerif] = useState(false)

  return (
    <div className='flex flex-col items-center  w-[600px] lg:w-full '>
      <img src={scanPhoto} className="sm:w-[350px] w-[400px]" />
      <div className="text-[#1D3A70] text-[24px] font-bold text-center mt-5">Nous devons vérifier votre identité</div>
      <p className="text-[#6B7280] text-[15px] text-center">
        Afin de vérifier votre identité, nous acceptons les documents suivants :
        
      </p>
      <ul className='text-[#6B7280] py-4 text-[15px] list-inside list-disc '>
          <li>Carte d&apos;identité européenne</li>
          <li> Tous les passeports</li>
          <li> Titre de séjour</li>
        </ul>
      <p className="text-[#6B7280] text-[15px] sm:text-[14px] text-center">
        Pour garantir la sécurité de la vérification, nous vous demanderons de filmer votre document en quelques étapes rapides sur notre plateforme, en moins de 5 minutes.
      </p>
      <div className="flex items-center justify-around rounded-lg bg-[#5754E533] mt-5 p-5 maw-w-max">
        <img src={iconLock} />
        <div className="text-[#0C266C] font-semibold text-[15px] sm:text-[14px] pl-4">
          Nous prenons la sécurité de vos données très au sérieux. <br /> Elles ne seront utilisées qu&apos;à des fins
          de vérification.
        </div>
      </div>
      <div className="flex justify-center">
        <button
          type="submit"
          disabled={onVerif}
          onClick={()=>{handleClick(), setOnVerif(true)}}
          className={`text-white text-[16px] font-bold p-4 w-[400px] rounded-xl sm:w-[300px] sm:text-[14px] mt-8 
          ${onVerif ? 'bg-gray-400' : 'bg-[#4743C9]'}`}>
          Vérifiez votre identité
        </button>
      </div>
    </div>
  )
}

export default PersonalInformationContainer
