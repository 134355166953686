
import UserContext from 'contexts/UserContext'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { invoiceSherlockSecure } from 'requests/paiement'
import { RechargeSherlockSecure } from 'requests/recharge'
import { Spinner } from 'utils/loading'

const SecureCard = () => {

  const { user } = useContext(UserContext)

  const navigate = useNavigate()

  const [responseSuccess, setResponseSuccess] = useState(false)
  const [responseFailed, setResponseFailed] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const token = user?.token?.accessToken
      const body = {
        paResMessage: localStorage.getItem('paReqMessage'),
        redirectionData: localStorage.getItem('redirectionData'),
        transactionReference: localStorage.getItem('reference'),
        context: localStorage.getItem('context'),
        platform : 'web'

      }
      try {
        if(body.context === 'recharge'){
                  const response = await RechargeSherlockSecure(token, body)
         if (response?.success) {
           setResponseSuccess(true)
           navigate('/success' , { state: { responseSuccess, recharge: 2 }})
         } else {
           setResponseFailed(true)
           navigate('/error' , { state: { responseSuccess, recharge: 2 }})
         }

        } else if(body.context === 'invoice') {
          const response = await invoiceSherlockSecure(token, body)
          if (response?.success) {
            
            setResponseSuccess(true)
            //remove from localStorage
            localStorage.removeItem('paResMessage')
            localStorage.removeItem('redirectionData')
            localStorage.removeItem('reference')
            localStorage.removeItem('context')
            navigate('/success' , { state: { responseSuccess, invoice: 3 }})
          } else {
            setResponseFailed(true)
            navigate('/error' , { state: { responseSuccess, invoice: 3 }})
          }
 
         }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  return (
    <div className="flex flex-col items-center ml-0">
      <div className="text-[35px] my-[50px] font-semibold">Authentification ...</div>
      <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
    </div>
  )
}

export default SecureCard
