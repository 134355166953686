import React, { useContext, useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import RootContainer from 'containers/RootContainer'
import ModalSowCountry from 'containers/homeContainers/modalShowCountry'
import localforage from 'localforage'
import UserContext from 'contexts/UserContext'
import { updateCountry } from 'requests/user'
import { ToastContainer } from 'react-toastify'

const App = () => {
  const { user, setUser } = useContext(UserContext)

  const [showModalCountry, setShowModalCountry] = useState(false)
  const [isOperateur, setIsOperateur] = useState('')
  const [destinationDefaultCountry, setDestinationDefaultCountry] = useState('')
  const [operateur, setOperateur] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user?.user?.destinationDefaultCountry === '' || user?.user?.destinationDefaultCountry === undefined) {
      setShowModalCountry(true)
    } else {
      setShowModalCountry(false)
    }
  }, [user])
  const updateCountryUser = async () => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    try {
      const response = await updateCountry(id, token, destinationDefaultCountry)
      if (response?.success) {
        if (user) {
          setUser({ token: user.token, success: true, user: response.user }),
            localforage.setItem('auth', JSON.stringify({ token: user.token, success: true, user: response.user }))
        }
        setShowModalCountry(false)
      } else {
        console.log(response)
      }
    } catch (error) {
      console.error('Error updating:', error)
    }
  }
  return (
    <BrowserRouter>
      <ToastContainer />

      <ModalSowCountry
        isOpen={showModalCountry}
        closeModal={() => setShowModalCountry(false)}
        isOperateur={isOperateur}
        updateCountryUser={updateCountryUser}
        setOperateur={(value: string) => setOperateur(value)}
        onChange={(value: string) => setIsOperateur(value)}
        setDestinationDefaultCountry={(value: string) => setDestinationDefaultCountry(value)}
      />
      <RootContainer />
    </BrowserRouter>
  )
}

export default App
