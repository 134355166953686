import React from 'react'
import { useNavigate } from 'react-router-dom'
interface Props {
  isIos: boolean
  isAndroid: boolean
  closeModal: () => void
}

const ModalReinitialiserPasswordMobile: React.FC<Props> = ({ isIos, isAndroid, closeModal }) => {
  const navigate = useNavigate()

  return (
    <div className="justify-center items-center flex fixed inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm">
      <div className="flex items-center gap-3 justify-between p-5 bg-white">
        <button
          onClick={() => {
            closeModal()
            navigate('/login')
          
          }}
          className=" bg-blue-950 p-2 text-[#FFFFFF]"
        >
          versWeb
        </button>
        <button
          onClick={() => {
            closeModal()
            if (isAndroid) {
              window.open('https://play.google.com/store/apps/details?id=com.myeasytransfert&hl=en_US', '_blank')
            } else if (isIos) {
              window.open('https://apps.apple.com/ci/app/my-easy-transfer/id1572782943', '_blank')
            }
            
          }}
          className="bg-blue-950 p-2 text-[#FFFFFF]"
        >
          versStore
        </button>
      </div>
    </div>
  )
}

export default ModalReinitialiserPasswordMobile
